.dpp-sidebar {
    width: 200px;
    background-color: #ffffff;
    border-right: 1px solid #e0e0e0;
    padding: 20px 0;
  }
  
  .dpp-sidebar nav {
    display: flex;
    flex-direction: column;
  }
  
  .dpp-back-button {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;
    padding: 10px 20px;
    margin-bottom: 20px;
  }
  
  .dpp-menubutton {
    padding: 15px 0px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #757575;
    transition: all 0.3s ease;
  }
  
  .dpp-menubutton.active {
    border-bottom: 2px solid #000;
    color: #000;
    font-weight: 700;
  }
  
  .dpp-menubutton:hover {
    background-color: #f5f5f5;
  }
  
  .dpp-menuText {
    display: flex;
    align-items: center;
  }
  
  .dpp-logout {
    color: #ff0000;
    margin-top: auto;
  }

  .dpp-menuImg{
    width: 45px;
    height: 45px;
  }