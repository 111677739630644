.snp-div {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  padding: 36px 0 80px;
}
.snp-div-2 {
  display: flex;
  width: 100%;
  max-width: 1383px;
  gap: 20px;
  text-align: center;
  padding: 0 20px;
}
@media (max-width: 991px) {
  .snp-div-2 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.snp-div-3 {
  color: #000;
  flex-grow: 1;
  flex-basis: auto;
  text-align: left;
  margin: auto 0;
  font: 40px Inter, sans-serif;
}
.snp-div-4 {
  display: flex;
  gap: 20px;
  font-size: 24px;
  justify-content: space-between;
}
.snp-div-5 {
  color: #000;
  font-family: Inter, sans-serif;
  margin: auto 0;
}
.snp-div-7 {
  border-color: rgba(94, 94, 94, 1);
  border-width: 1px;
  background-color: #5e5e5e;
  align-self: stretch;
  min-height: 1px;
  margin-top: 23px;
  width: 100%;
}
@media (max-width: 991px) {
  .snp-div-7 {
    max-width: 100%;
  }
}
.snp-div-8 {
  color: #000;
  text-align: center;
  margin-top: 200px;
  font-weight: 600;
  font-size: 40px;
  font: 600 48px Baloo Bhaijaan 2, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .snp-div-8 {
    margin-top: 40px;
    font-size: 40px;
  }
}
.snp-div-9 {
  color: #5e5e5e;
  text-align: center;
  margin-top: 9px;
  font: 16px Actor, sans-serif;
}
.snp-div-10 {
  display: flex;
  margin-top: 67px;
  gap: 14px;
  font-size: 16px;
  color: #5e5e5e;
  white-space: nowrap;
}
@media (max-width: 991px) {
  .snp-div-10 {
    margin-top: 40px;
    white-space: initial;
  }
}
.snp-div-13 {
  color: #fa5252;
  margin-top: 16px;
  font: 20px Actor, sans-serif;
}

.snp-div-14 {
  display: flex;
  margin-top: 16px;
  gap: 14px;
  font-size: 16px;
  color: #5e5e5e;
}
.snp-div-17 {
  border-radius: 10px;
  background-color: #01b6cd;
  margin-top: 40px;
  width: 367px;
  max-width: 100%;
  align-items: center;
  color: #fff;
  text-align: center;
  justify-content: center;
  padding: 17px 60px;
  font: 600 24px Baloo Paaji 2, -apple-system, Roboto, Helvetica, sans-serif;
  outline: auto;
}
@media (max-width: 991px) {
  .snp-div-17 {
    padding: 0 20px;
  }
}
.snp-div-18 {
  display: flex;
  margin-top: 47px;
  gap: 9px;
  font-size: 16px;
  color: #5e5e5e;
  text-align: center;
}
@media (max-width: 991px) {
  .snp-div-18 {
    margin-top: 40px;
  }
}
.snp-img-2 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 10%;
  align-self: start;
  padding-left: 45px;
}
.snp-div-19 {
  font-family: Actor, sans-serif;
}
.snp-span-1 {
  font-weight: 600;
  color: rgba(1, 182, 205, 1);
}
.snp-span-2 {
  font-weight: 600;
}
.snp-input {
  font-family: Actor, sans-serif;
  margin: auto 0;
  width: 300px;
  height: 40px;
  padding: 0 10px;
  font-size: 16px;
}

.snp-a{
  text-decoration-line: none;
}

.rp-ForgetPasswordPage_div-13.snp-loading-button {
  opacity: 0.7;
  cursor: not-allowed;
  position: relative;
}

.rp-ForgetPasswordPage_div-13 .snp-button-text {
  display: inline-block;
  transition: opacity 0.2s;
}

.rp-ForgetPasswordPage_div-13 .snp-button-text.snp-loading {
  opacity: 0;
}

.rp-ForgetPasswordPage_div-13 .snp-loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border: 3px solid #ffffff;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: snp-spin 1s linear infinite;
}

@keyframes snp-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}