/* 전체 레이아웃 스타일 */
.dpp-doctor-profile {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.dpp-content {
  display: flex;
  flex: 1;
}

.dpp-sidebar {
  width: 250px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px #AFAFAF
}

.dpp-main-content {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.as_dpp-main-content {
  flex: 1;
  display: flex;
  padding: 40px;
}

/* 헤더 스타일 */
.wcc-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1;
}

.wcc-logo-image {
  height: 40px;
}

.wcc-header-right {
  display: flex;
  align-items: center;
}

.wcc-notification-button {
  background: none;
  border: none;
  cursor: pointer;
}

.wcc-drprofile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

/* 사이드바 네비게이션 스타일 */
.dpp-sidebar nav {
  display: flex;
  flex-direction: column;
}

.dpp-menubutton {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dpp-menubutton:hover, .dpp-menubutton.active {
  background-color: #e0e0e0;
}

.dpp-menu-img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.dpp-menuText {
  font-size: 16px;
}

.dpp-logout {
  color: #ff0000;
}

/* Pay 섹션 스타일 */
.pay-section {
  padding: 20px;
  width: 100%;
  overflow-y: auto;
}

.pay-section h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.pay-header {
  margin-bottom: 20px;
}

.pay-header select {
  padding: 5px 10px;
  font-size: 16px;
}

.total-amount {
  display: flow;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  justify-items: right;
}

.ps_total{
  font-size: 12px;
  color:  #4B5354;
}

.ps_ta{
  font: 16px;
  color: #2F3637;
}

.ps_title{
  font-size: 20px;
  color: #2E7C9A;
  font-weight: bold;
}

.ps_img{
  width: 40px;
  height: 40px;
}

.ps_headerContainer{
  display: flex;
  align-items: center;
  gap: 15px;
}

/* 테이블 스타일 */
.income-table, .daily-table {
  width: 100%;
  border-collapse: collapse;
}

.income-table th, .daily-table th {
  background-color: #2E7C9A;
  padding: 12px;
  text-align: left;
  font-weight: bold;
  color: #fff;
  font-size: 16px;
}

.income-table td, .daily-table td {
  padding: 12px;
}

.income-table tr:hover, .daily-table tr:hover {
  background-color: #f9f9f9;
}

.arrow-container {
}

.arrow2 {
  
  color: #888;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
  transition: transform 0.3s ease;
}

.arrow-icon {
  height: 45px;
  width: 45px;
  transition: transform 0.3s ease;
}


.daily-table tr {
  cursor: pointer;
}

.daily-table tr.expanded {
  background-color: #f5f5f5;
}

.consultation-details {
  background-color: #f9f9f9;
}

.consultation-table {
  width: 100%;
  margin-top: 10px;
}

.consultation-table th, .consultation-table td {
  padding: 8px;
  border: none;
}

/* 주간 뷰 스타일 */
.weekly-view {
  background: white;
  overflow: hidden;
  height: calc(100vh - 200px);
}

.week-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}



.week-total {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
}

/* 다운로드 버튼 스타일 */
.download-button-container {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 1000;
}

/* 다운로드 버튼 스타일 수정 */
.download-button {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease;
  color: #839699;
  font-size: 16px;
  font-weight: bold;
}

.download-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}


.download-icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

/* 상태 스타일 */
.completed {
  color: #01B6CD;
  font-size: 16px;
  font-weight: bold;
}

.pending {
  font-size: 16px;
  color: #839699;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: 20px 0;
  background: white;
  border-radius: 8px;
  max-height: 600px; /* 약 8개의 행이 보이도록 설정 */
  overflow-y: auto;
}

/* 기존 income-table 스타일 수정 */
.income-table {
  min-width: 750px; /* 최소 너비 설정 */
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.income-table th {
  background-color: #2E7C9A;
  padding: 16px;
  text-align: left;
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  white-space: nowrap;
  position: sticky;
  top: 0;
}

.income-table td {
  border-bottom: 1px solid #A8A7A7;
  font-size: 14px;
}

/* 반응형 열 너비 조정 */
.income-table th:nth-child(1),
.income-table td:nth-child(1) {
  width: 30%;
  min-width: 200px;
  padding-left: 100px;
}

.income-table th:nth-child(2),
.income-table td:nth-child(2) {
  width: 25%;
  min-width: 150px;
}

.income-table th:nth-child(3),
.income-table td:nth-child(3) {
  width: 25%;
  min-width: 150px;
}

.income-table th:nth-child(4),
.income-table td:nth-child(4) {
  width: 20%;
  min-width: 150px;
}

/* 모바일 최적화 */
@media screen and (max-width: 768px) {
  .pay-section {
    padding: 10px;
  }
  
  .ps_headerContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .total-amount {
    padding: 15px;
    margin: 10px 0;
  }

  .ps_ta {
    font-size: 14px;
  }
  
  .download-button {
    width: 100%;
    justify-content: center;
    margin: 15px 0;
  }
}

/* 테이블 행 호버 효과 개선 */
.income-table tbody tr {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.income-table tbody tr:hover {
  background-color: rgba(46, 124, 154, 0.05);
}

/* 화살표 아이콘 스타일 개선 */
.arrow-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.arrow-icon {
  height: 24px;
  width: 24px;
  transition: transform 0.2s ease;
}

tr:hover .arrow-icon {
  transform: translateX(4px);
}

.income-table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #2E7C9A;
}

/* 스크롤바 스타일링 */
.table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb {
  background: #2E7C9A;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #236b85;
}

/* 모바일 반응형 조정 */
@media screen and (max-width: 768px) {
  .download-button-container {
    bottom: 20px;
    right: 20px;
  }

  .download-button {
    padding: 10px 16px;
    font-size: 14px;
  }

  .table-container {
    max-height: 500px; /* 모바일에서 높이 조정 */
  }
}
/* PaySection.css에 추가/수정할 스타일 */

.weekly-view {
  background: white;
  margin-top: 20px;
}

.week-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e7eb;
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-button img {
  width: 20px;
  height: 20px;
}

.week-total {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #2E7C9A;
}

.daily-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.daily-table th {
  background-color: #2E7C9A;
  color: white;
  padding: 12px 16px;
  text-align: left;
  font-weight: 500;
}

.daily-table td {
  padding: 12px 16px;
  border-bottom: 1px solid #e5e7eb;
}

.daily-table tbody tr {
  cursor: pointer;
  transition: background-color 0.2s;
}

.daily-table tbody tr:hover {
  background-color: rgba(46, 124, 154, 0.05);
}

.expanded-row {
  background-color: #f8f9fa;
}

.cases-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.arrow2 {
  font-size: 12px;
  transition: transform 0.2s;
}

.arrow2.expanded {
  transform: rotate(180deg);
}

.consultation-details {
  padding: 15px;
  background-color: #f8f9fa;
}

.consultation-table {
  width: 100%;
  margin-top: 10px;
}

.consultation-table th {
  background-color: #4B5354;
  color: white;
  font-size: 14px;
  padding: 10px;
}

.consultation-table td {
  padding: 10px;
  border-bottom: 1px solid #e5e7eb;
  font-size: 14px;
}

.details-row {
  background-color: #f8f9fa;
}

/* 모바일 반응형 */
@media (max-width: 768px) {
  .weekly-view {
    padding: 15px;
    margin-top: 15px;
  }

  .daily-table th,
  .daily-table td,
  .consultation-table th,
  .consultation-table td {
    padding: 10px;
    font-size: 14px;
  }
  
  .week-total {
    font-size: 16px;
  }
}

/* Weekly View 스타일 */
.weekly-view {
  background: white;
  overflow: hidden;
}

/* 연도 헤더 */
.year-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2E7C9A;
  color: white;
  padding: 12px 20px;
  height: 36px;
}

/* 주간 네비게이션 */
.week-navigation2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 15px;
}

.nav-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.nav-button img {
  height: 45px;
  width: 45px;
}

/* 총액 섹션 */
.total-section {
  border-bottom: 1px solid #839699;
  justify-content: space-between;
  display: flex;
}

.total-label {
  color: #666;
  font-size: 14px;
  margin-bottom: 5px;
  margin-left: 20px;
}

.total-label2{
  font-size: 16px;
  color: #4B5354;
}

.total-amount {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.total-amount{
  font-size: 28px;
  color: #2F3637;
}

.total-info {
  display: flex;
  align-items: center;
}

.income-label {
  color: #01B6CD;
  font-weight: 500;
  font-size: 20px;
  align-self: center;
}

.cases-count {
  align-self: center;
  font-size: 20px;
  color: #2F3637;
}

/* 일별 리스트 */
.daily-list {
}

.daily-item {
  background-color: #F9F9F9;
}

.daily-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
}

.daily-header:hover {
  background-color: #f8f9fa;
}

.day-info {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #666;
}

.arrow-icon {
  font-size: 12px;
  color: #999;
  transition: transform 0.2s;
  place-content: center;
  text-align-last: center;
}

.daily-header.expanded .arrow-icon {
  transform: rotate(90deg);
}

.day-date {
  color: #333;
}

.day-amount {
  font-weight: 500;
}

.day-cases {
  color: #666;
  margin-right: 20px;
}

/* 진료 상세 테이블 */
.treatments-table {
  background-color: #f8f9fa;
  padding: 15px;
  margin: 0 -20px;
}

.treatments-table table {
  width: 100%;
  border-collapse: collapse;
}

.treatments-table th {
  background-color: #eee;
  color: #4B5354;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 30px;
  text-align: left;
}

.treatments-table td {
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  color: #333;
}

/* 반응형 */
@media (max-width: 768px) {
  .total-amount {
    font-size: 20px;
  }
  
  .daily-header {
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .treatments-table {
    overflow-x: auto;
  }
}

.weekly-view {
  background: white;
  overflow: hidden;
  height: calc(94vh - 200px);
}

.daily-list {
  overflow-y: auto;
  max-height: calc(100% - 230px); /* 상단의 year-header, week-navigation2, total-section 영역을 제외한 높이 */
  padding-right: 8px; /* 스크롤바 공간 확보 */
}

/* 스크롤바 스타일링 */
.daily-list::-webkit-scrollbar {
  width: 8px;
}

.daily-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.daily-list::-webkit-scrollbar-thumb {
  background: #2E7C9A;
  border-radius: 4px;
}

.daily-list::-webkit-scrollbar-thumb:hover {
  background: #236b85;
}

.daily-item {
  padding: 0 20px; /* 좌우 패딩 추가 */
  border-bottom: 1px solid #e5e7eb;
}

.daily-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
}

.treatments-table {
  background-color: #f8f9fa;
}

.treatments-table table {
  width: 100%;
  border-collapse: collapse;
}

.treatments-table th,
.treatments-table td {
  padding: 12px 0; /* 좌우 패딩 제거 */
  text-align: left;
}

/* 각 컬럼의 너비와 정렬을 daily-header와 맞춤 */
.treatments-table th:first-child,
.treatments-table td:first-child {
  padding-left: 40px; /* arrow-icon 공간 고려 */
  width: 30%;
}

.treatments-table th:nth-child(2),
.treatments-table td:nth-child(2) {
  width: 20%;
  text-align: center; /* 금액 중앙 정렬 */
}

.treatments-table th:last-child,
.treatments-table td:last-child {
  padding-right: 0;
  text-align: right;
}

/* daily-header의 각 요소 정렬 */
.day-info {
  width: 30%;
  display: flex;
  align-items: center;
}

.day-amount {
  width: 20%;
  text-align: center;
}

.day-cases {
  text-align: right;
  padding-right: 0;
}

.arrow-icon {
  margin-right: 10px;
  min-width: 20px; /* arrow-icon의 고정 너비 */
}

/* 진료 상세 테이블 */
.treatments-table {
  background-color: #f8f9fa;
  padding: 15px 20px; /* 좌우 패딩을 daily-item과 동일하게 설정 */
}

.treatments-table table {
  width: 100%;
  border-collapse: collapse;
}

.treatments-table th {
  background-color: #eee;
  color: #4B5354;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 20px;
  text-align: left;
}

.treatments-table td {
  padding: 12px 20px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  color: #333;
}

/* 컬럼 너비 조정 */
.treatments-table th:first-child,
.treatments-table td:first-child {
  width: 31%;
  padding-left: 20px; /* 첫 번째 컬럼 패딩 조정 */
  background-color: #EFF2F3;
  font-size: 16px;
}

.treatments-table th:nth-child(2),
.treatments-table td:nth-child(2) {
  width: 20%;
  background-color: #EFF2F3;
  font-size: 16px;
}

.treatments-table th:last-child,
.treatments-table td:last-child {
  width: 15%;
  padding-right: 20px; /* 마지막 컬럼 패딩 조정 */
  text-align: left;
  background-color: #EFF2F3;
  font-size: 16px;
}

.wcc_paySection {
  width: auto;
  background-color: #fff;
  padding: 0;
  margin: 15px 20px;
  border: none;
  border-radius: 15px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 0 20px -5px #a9a9a9;
  /* Default height for larger screens (monitors) */
  height: 30%;
}

/* MacBook Air (13-inch) */
@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .wcc_paySection {
    height: 25%;
  }
}

/* MacBook Pro (14-inch and 16-inch) */
@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .wcc_paySection {
    height: 25%;
  }
}

/* Added safety for very small screens */
@media screen and (max-height: 800px) {
  .wcc_paySection {
    height: 35%; /* Slightly higher percentage for smaller screens */
  }
}

/* Added safety for very large screens */
@media screen and (min-height: 1440px) {
  .wcc_paySection {
    height: 30%; /* Maintain larger height for big monitors */
  }
}