@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&display=swap');
/* Step2_PersonalInfo.css */


body {
  font-family: 'Figtree', sans-serif;
}


/* Container and General Styles */
.step3-container {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.step3-input-group {
  position: relative;
  margin-bottom: 10px;
}

/* Back Arrow */
.step3-back-arrow {
  position: absolute;
  top: 23px;
  left: 30px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

/* Title and Subtitle */
.step3-title {
  font-family: 'Figtree', sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  margin-top: 40px;

  margin-bottom: -2px; /* 기존 마진 유지 */
  color:#2F3637
}

.step3-subtitle {
  text-align: center;
  
  color: #2F3637;
  font-size: 20px;
  margin-top: 15px;
}

.step3-section-title {
  font-family: 'Figtree', sans-serif;
  font-size: 16px;
  margin-bottom: 15px;
  color: #333;
}

/* Search Container */
.step3-search-container {
  position: relative;
  margin-bottom: 20px;
}

/* Address Search Input */
.step3-address-search-input {
  width: 81%;
  padding: 10px 40px 10px 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 14px;
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 20px;
  font-family: 'Figtree', sans-serif;
  background-color: #EFF2F3;
}

.step3-input.error,
.step3-select.error,
.step3-address-input.error,
.step3-textarea.error {
  border-color: #ff3b30;
}

/* Predictions List */
.step3-predictions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 0 0 10px 10px;
  width: 105%;
}

.step3-prediction-item {
  padding: 10px 15px;
  cursor: pointer;
}

.step3-prediction-item:hover {
  background-color: #f0f0f0;
}

/* Address Details */
.step3-address-details {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px;
}

.step3-input-wrapper {
  flex: 1;
  position: relative;
  min-width: 0; /* 추가 */
}

.step3-input,
.step3-custom-select {
  width: 100%;
  box-sizing: border-box; /* 추가 */
}

/* General Input Styles */
.step3-input {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 14px;
  background-color: transparent;
  font-family: 'Figtree', sans-serif;
}

/* 드롭다운 메뉴가 잘리지 않도록 보장 */
.step3-container {
  overflow: visible;
}

.step3-address-section {
  overflow: visible;
}


.step3-input::placeholder {
  color: #999;
  font-family: 'Figtree', sans-serif;
}

.step3-input:focus {
  outline: none;
  border-bottom-color: #01B6CD;
}

/* Address Input Styles */
.step3-address-input {
  width: 92%;
  padding: 10px 0 10px 30px;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 14px;
  background-color: transparent;
  font-family: 'Figtree', sans-serif;
}

.step3-address-input::placeholder {
  color: #999;
}

.step3-address-input:focus {
  outline: none;
  border-bottom-color: #01B6CD;
}

/* Select Styles */
.step3-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  appearance: none;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMiA0TDYgOEwxMCA0IiBzdHJva2U9IiMzMzMzMzMiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==');
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 12px;
  padding-left: 19px;
}

.step3-select::placeholder {
  color: #999;
}

.step3-select:focus {
  outline: none;
  border-color: #01B6CD;
}

.step3-input-icon {
  position: absolute;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
}

/* Career Section */
.step3-career-section {
  margin-top: 30px;
}

/* Textarea Styles */
.step3-textarea {
  width: 94%;
  height: 130px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 10px;
  resize: none;
  font-size: 14px;
  line-height: 1.5;
  font-family: 'Figtree', sans-serif;
}

.step3-textarea:focus {
  outline: none;
  border-color: #01B6CD;
}

.step3-textarea.focused {
  border-color: #01B6CD;
  outline: none;
}

/* Error Styles */
.step3-error-text {
  color: #ff3b30;
  font-size: 12px;
  float: right;
  font-weight: bold;
}

.step3-input.error,
.step3-select.error,
.step3-address-input.error,
.step3-textarea.error {
  border-color: #ff3b30;
}

/* Select Dropdown */
.step3-select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.step3-custom-select:focus {
  outline: none;
  border-bottom-color: #01B6CD;
}

.step3-select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  width: 100%;
  
}

.step3-select-option {
  padding: 10px;
  cursor: pointer;
  font-family: 'Figtree', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.step3-select-option:hover {
  background-color: #f0f0f0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .step3-address-details {
    flex-direction: column;
  }

  .step3-input-wrapper {
    position: relative;
  width: 100%;
  margin-bottom: 10px;
  }

  .step3-select-dropdown {
    max-height: 150px;
  }
}

.st3-next-button
{
  width: 80%;
  background-color: #01b6cd;
  color: white;
  border: none;
  padding: 15px 10px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 20px;
  margin-top: 20px;
}

.st3-button_center {
  text-align-last: center;
  margin-bottom: -10px;
}


.step3-custom-select {
  width: 100%;
  padding: 10px 20px 10px 0;
  border: none;
  border-bottom: 2px solid #ccc;
  background-color: transparent;
  font-family: 'Figtree', sans-serif;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  position: relative;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMiA0TDYgOEwxMCA0IiBzdHJva2U9IiMzMzMzMzMiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 12px;
  min-width: 120px; /* 최소 너비 설정 */
  max-width: 200px; /* 최대 너비 설정 */
  overflow: hidden;
  text-overflow: ellipsis;
}

.step3-custom-select.open {
  border-bottom-color: #01B6CD;
}




.step3-custom-select::after {
  
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
}



.step3-select-options::-webkit-scrollbar {
  width: 6px;
}

.step3-select-options::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.step3-select-options::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.step3-select-options::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.step3-custom-select.error {
  border-bottom-color: #ff3b30;
}

