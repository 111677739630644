.patient-history {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 96%;
  padding: 20px 20px 20px;
  position: relative;
}

.eventPatient-history {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 90%;
  margin-bottom: 70px;
}

.event-item{
  padding: 0 0 15px 0;
}

.back-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
  color: #01b6cd;
}

.rpv-default-layout__toolbar {
  display: none !important;
}

/* 툴바가 차지하던 공간 제거 */
.rpv-default-layout__container {
  padding-top: 0 !important;
}

/* 필요한 경우, 뷰어의 높이 조정 */
.rpv-core__viewer {
  height: 100% !important;
}

.rpv-default-layout__body {
  padding-top: 0 !important;
}

/* 추가적으로, 컨테이너의 높이를 조정할 필요가 있을 수 있습니다 */
.rpv-default-layout__container {
  height: 100% !important;
}

/* PDF 뷰어가 전체 높이를 차지하도록 설정 */
.rpv-core__viewer {
  height: 100% !important;
}

.history-title {
  margin-bottom: 20px;
  font-size: 24px;
}
.ph_title{
  width: 100%;
  font-size: 20px;
  color: #01B6CD;
  font-weight: bold;
  padding: 10px 0;
}

.PatientHistory_div-13 {
  align-items: baseline;
  display: flex;
  padding: 5px 0;
  padding-bottom: 15px;
  border-bottom: solid 1px #D3D8D9;
}

.PatientHistory_div-13-2 {
  align-items: baseline;
  display: flex;
}

.history-list {
  flex-grow: 1;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 700px;
}

.peh_result{
  margin: 15px 0;
  font-size: 16px;
  font-weight: bold;
}

.eventPatient-history::-webkit-scrollbar {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #D3D8D9;
}

.eventPatient-history::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #2E7C9A;
}

.history-list::-webkit-scrollbar {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #D3D8D9;
}

.history-list::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #2E7C9A;
}

.history-item {
  margin-bottom: 10px;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
}

.history-item.open {
  margin-bottom: 20px;
}

.history-header {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 16px;
}

.toggle-arrow {
  margin-right: 10px;
  transition: transform 0.3s ease;
  color: #9CABAD;
}

.toggle-arrow.open {
  transform: rotate(0deg);
}

.pdf-preview {
  height: 190px;
  overflow: hidden;
  width: 100%;
}

.pdf-container {
  width: 100%;
  height: 100%;
}


.back-button {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

/* PDF viewer customizations */
.rpv-core__viewer {
  border: 1px solid #e0e0e0;
}

.rpv-core__inner-page {
  cursor: grab;
}

.rpv-core__inner-page:active {
  cursor: grabbing;
}

/* Ensure zoom buttons are visible */
.rpv-zoom__popover-target {
  display: inline-flex !important;
}

/* Hide all toolbar items except zoom */
.rpv-core__minimal-button:not(.rpv-zoom__popover-target) {
  display: none !important;
}

.history-item {
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.history-header {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
}

.toggle-arrow {
  margin-right: 10px;
  transition: transform 0.3s ease;
  color: #9CABAD;
}

.toggle-arrow.close {
  transform: rotate(0deg);
}

.img_patientSearch{
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.pdf-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.pdf-preview {
  border-radius: 5px;
  height: 200px;
  overflow: hidden;
  width: 102%;
  padding-bottom: 18px;
  text-align: right;
}

.pdf-container {
  width: 100%;
  height: 100%;
}

/* PDF viewer customizations */
.rpv-core__viewer {
  border: none;
}

.rpv-core__inner-page {
  cursor: default;
}

/* 확대/축소 버튼 스타일 조정 */
.rpv-zoom__popover-target {
  display: inline-flex !important;
  background-color: #f0f0f0;
  border-radius: 3px;
  padding: 2px;
}

/* 불필요한 버튼 숨기기 */
.rpv-core__minimal-button:not(.rpv-zoom__popover-target) {
  display: none !important;
}