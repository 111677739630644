.start-page-footer_Style_overflow {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  overflow-x: clip;
}

.start-page-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  font-family: Inter, sans-serif;
  width: 100%;
  max-width: 1465px;
  margin: 0 auto;
}

.start-page-hero{
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 150px;
}

.start-page-main-content {
  background-color: #EEF1F5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  font-family: Inter, sans-serif;
  width: 100%;
  margin: 0 auto;
}

.start-page-fs0_topNaviBar {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  z-index: 1;
  box-shadow: 0 0 0 #fff;
  width: 100%;
}

.Start-page-logo-image {
  height: 35px;
  display: flex;
  align-items: center;
  margin: 20px 0 0 20px;
}

.start-page-menu-image {
  display: none;
}

.fs0_MenuContainerMobile {
  display: none;
}

.start-page-fs0_logo_container {
  color: #000;
  flex-grow: 1;
  flex-basis: auto;
  text-align: left;
  font: 600 40px Inter, sans-serif;
  color: #000;
  flex-basis: auto;
  flex-grow: 1;
  font: 600 40px Inter, sans-serif;
  margin: auto 0;
  text-align: left;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
}

.start-page-hero-h2  {
  font-size: 70px;
  margin-bottom: 1rem;
  font-weight: 600;
}

.start-page-hero-content {
  flex: 1;
  padding-left: 25px;
  text-align: left;
}

.hero h1 {
  font-size: 20px;
  color: #333;
  margin-bottom: 0.5rem;
}

.start-page-p {
  font-size: 20px;
  margin-bottom: 70px;
  color: #666;
}

.start-page-mediknocks-main {
  font-size: 80px;
  color: #01b6cd;
}

.mediknocks {
  color: #01b6cd;
}

.start-page-cta-button1 {
  height: 55px;
  width: auto;
  margin-right: 10px;
  margin-top: 12px;
}

.start-page-cta-button2 {
  height: 80px;
  width: auto;
  margin-right: 10px;
}

.start-page-h2{
  font-size: 1.5em;
}

.start-page-cta-button3 {
  background-color: #fff;
  border-color: #01b6cd;
  border-radius: 5px;
  border-style: solid;
  color: #01b6cd;
  cursor: pointer;
  font-size: 1rem;
  height: 53px;
  padding: .75rem 2rem;
  transition: background-color .3s ease;
  width: 170px;
  margin-top: 13px;
}

.cta-button:hover {
  background-color: #019aad;
}

.start-page-btn-group{
  display: flex;
}

.start-page-image {
  flex: 1;
}

.start-page-image img {
  max-width: 100%;
  border-radius: 10px;
}

.demo a {
  position: static;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #CDCDCD;
  font : normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
}
.demo a:hover {
  opacity: .5;
}

#section05 a {
  padding-top: 70px;
}
#section05 a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #CDCDCD;
  border-bottom: 1px solid #CDCDCD;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

.start-page-what-is1, .doctors {
  background-color: #EEF1F5;
  padding: 0 0 7rem 0;
}

.start-page-what-is2, .doctors {
  background-color: #EEF1F5;
  padding: 3rem;
  text-align: right;
}

.start-page-what-is4, .doctors {
  background-color: #EEF1F5;
  padding: 3rem 3rem 0 3rem;
}

.start-page-what-is, .doctors {
  background-color: #EEF1F5;
  padding: 3rem;
}

.insurance {
  padding: 3rem 0;
}

.what-is h2, .insurance h2, .doctors h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
}

.what-is-content, .insurance-content, .doctors-content {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.what-is-content1{
  display: block;
  align-items: center;
  gap: 2rem;
}

.what-is-content3{
  display: block;
  align-items: center;
  gap: 2rem;
}

.start-page-img-2{
  width:600px;
  border-radius: 10px;
}

.start-page-img-3-1{
  width:300px;
  border-radius: 10px;
}

.start-page-img-3-2{
  width:300px;
  border-radius: 10px;
  display: none;
}


.start-page-img-4{
  width:700px;
  border-radius: 10px;
}

.start-page-img-5{
  width:500px;
  border-radius: 10px;
}

.what-is-content img, .doctors-content img {
  max-width: 39%;
  border-radius: 10px;
  margin: 30px;
}

.insurance-content img {
  max-width: 42%;
  border-radius: 10px;
  margin: 30px;
}

.start-page-what-is-text, .doctors-text {
  flex: 1;
  text-align: left;
  margin-bottom: auto;
  padding-left: 35px;
  padding-top: 40px;
}

.start-page-what-is-text3, .doctors-text {
  flex: 1;
  text-align: center;
  margin-bottom: auto;
  padding-left: 35px;
  padding-top: 40px;
}

.insurance-text {
  flex: 1;
  text-align: right;
  margin-bottom: auto;
  padding-right: 5px;
}

.start-page-what-is-text h3, .insurance-text h3, .doctors-text h3, .st_div_text {
  font-size: 1.5rem;
  font-weight: 600;
}

.start-page-what-is-text p, .insurance-text p, .doctors-text p {
  color: #666;
  margin-bottom: 1rem;
}

.insurance-content, .doctors-content {
  flex-direction: row-reverse;
}

.footer-content p {
  color: #666;
  font-size: 0.9rem;
}

.st_div_fontUP{
  font-size: 20px;
  color: #5E5E5E;
}

.st_div_fontUP{
  font-size: 20px;
  color: #5E5E5E;
  margin-bottom: 30px;
}

.st_div_fontUP2 {
  font-size: 20px;
  color: #5E5E5E;
  text-align: right;
  padding: 0 0 40px 0;
}

.st_div_fontUP3{
  text-align: center;
  font-size: 20px;
  color: #5E5E5E;
  padding: 0 0 40px 0;
}

.try-now-btn2{
  text-align: right;
  padding: 20px 0 0 0;
}

.try-now-btn3{
  text-align: center;
  padding: 0 0 40px 0;
}

a{
  text-decoration: none;
  color: #000;
}

.start-page-last-bar{
  background-color: #01b6cd;
  width: 100%;
  height: 500px;
  padding: 60px;
}

.start-page-last-bar-text{
  color: #fff;
  text-align: left;
  margin-left: 10%;
  display: flex;
}

.start-page-last-bar-text-first{
  font-size:30px;
}

.start-page-last-bar-text-sec{
  font-size: 40px;
  font-weight: 500;
}

.start-page-last-btn{
  margin-left: 50%;
  display: block;
}

.start-page-cta-button4 {
  background-color: #ffffff;
  color: #01b6cd;
  border: none;
  border-radius: 20px;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 170px;
  height: auto;
  margin-bottom: 10px;
}

.start-page-cta-button5 {
  background-color: #01b6cd;
  color: #ffffff;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 20px;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 170px;
  height: auto;
}

@media (max-width: 768px) {
  .hero, .what-is-content, .insurance-content, .doctors-content {
    flex-direction: column;
  }

  .hero-content, .what-is-content img, .insurance-content img, .doctors-content img {
    padding-right: 0;
    margin-right: 0;
    max-width: 100%;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-content p {
    margin-bottom: 0.5rem;
  }  
}

.row_reverse{
  flex-direction: row-reverse;
}

.text_right{
  text-align: right;
}

.start-page-content-line{
  max-width: 113rem;
  width: 100%;
  position: relative;
  border-bottom: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
  border-image: initial;
  border-top: 1px solid rgb(200, 204, 208);
}

/* ... (previous styles remain the same) */

.footer-logo-image {
  height: 30px;
  display: inline-block;
  align-items: center;
  margin-bottom: 10px;
}

.footer {
  background-color: #f8f9fa;
  padding: 2rem 0;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  margin-top: 20px;
}

.footer-section {
  flex: 1;
  padding: 0 1rem;
  margin-right: 20px;
  text-align: left;
}

.footer-section h3 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
}

.footer-section p {
  font-size: 0.9rem;
  color: #666;
  margin: 0.25rem 0;
}

.start-page-sns-button1{
  width: 30px;
  margin: 15px 15px 100px 15px;
  cursor: pointer;
}

.start-page-sns-button1{
  width: 30px;
  margin: 15px 10px 100px 15px;
  cursor: pointer;
}

/* @media (max-width: 768px) {
  .footer-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .footer-section {
      margin-bottom: 1rem;
  }
} */

/* 언어 변경 */
.start-page-language-icon{
  width: 50px;
  height: 50px;
}
.start-page-nav-wrapper {
  width: 150px;
  text-align: center;
  margin-top: 10px;
}

.start-page-sl-nav {
  position: relative;
  display: flex;
  margin-left: 20px;
}

.start-page-language-icon {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: contents;
}

.start-page-dropdown-trigger {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.start-page-triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  margin-left: 5px;
}

.start-page-dropdown-menu {
  display: block;
  position: absolute;
  top: 100%;
  left: -29px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  min-width: 100px;
  z-index: 10;
}

.start-page-dropdown-menu li {
  padding: 5px 15px;
  cursor: pointer;
  white-space: nowrap; /* 텍스트가 줄바꿈되지 않도록 */
}

.start-page-dropdown-menu li:hover {
  background-color: #f5f5f5;
}

.start-page-active {
  font-weight: bold;
  color: #007bff;
}

.start-page-nav-wrapper-400{
  display: none;
}

/* 1000px 이하일 때 이미지 교체 */
@media (max-width: 1000px) {
  .start-page-fs0_logo_container{
    display: flex;
  }

  .start-page-image img {
    display: none; /* 기존 이미지 숨기기 */
  }

  .Start-page-logo-image{
    height: 35px;
    margin: 20px 0 0 20px;
  }
  
  /* 새로 추가한 아이콘 이미지 */
  .start-page-menu-image {
    display: flex;
    height: auto;
    position: sticky;
    left: 100%;
    top: 0;
    width: 70px;
  }

  /* 사이드바 스타일 */
.fs0_MenuContainerMobile {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* 배경 색상 */
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 20;
}

  .fs0_MenuContainerMobile.visible {
    transform: translateX(0); /* 메뉴 아이콘 클릭 시 보이도록 */
  }

  .start-page-close-btn {
    position: absolute;
    top: 30px;
    right: 40px;
    font-size: 1.5em;
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
  }

  .start-page-menu-list{
    list-style-type: none;
    text-align: left;
  }

  .start-page-menu-list-fs0_text{
    color: #666;
    font-family: Inter, sans-serif;
    font-weight: 500;
    margin: auto 0;
    overflow: hidden;
    position: relative;
    font-size: 20px;
  }
  .fs0_MenuContainer {
    display: none;
  }
  
  .start-page-fs0_topNaviBar{
    align-items: center;
    background-color: #fff;
    display: block;
    justify-content: space-between;
    padding: 10px 20px;
    z-index: 1;
    box-shadow: 0 0 0 #fff;
    width: 100%;
  }
  .start-page-fs0_logo_container {
    color: #000;
    flex-grow: 1;
    flex-basis: auto;
    text-align: left;
    font: 600 40px Inter, sans-serif;
    color: #000;
    flex-basis: auto;
    flex-grow: 1;
    font: 600 40px Inter, sans-serif;
    margin: auto 0;
    text-align: left;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
  }
  
  .start-page-img-3-1{
    display: none;
  }
  
  .start-page-img-3-2{
    display: block;
  }  
  .start-page-img-4{
    width: 500px;;
  }
  .start-page-last-bar-text-first{
    font-size: 25px;
  }
  .start-page-last-bar-text-sec{
    font-size: 35px;
  }
  .start-page-last-btn{
    margin-left: 20%;
  }

  .footer-content{
    display: block;
  }

  .footer-section{
    margin-bottom: 40px;
    text-align: center;
  }
  
}

@media (max-width: 680px){
  .start-page-main{
    padding: 0;
  }
  .start-page-hero-h2 {
    font-size: 50px;
    line-height: 1.5;
  }
  
  .start-page-mediknocks-main {
    font-size:55px;
  }

  .start-page-p {
    font-size: 13px;
  }

  .start-page-cta-button3{
    display: none;
  }

  .start-page-img-2{
    width: 100%;
  }

  .start-page-img-4{
    width: 100%;
  }

  .start-page-img-5{
    width: 100%;
  }

  .start-page-last-btn{
    margin-left: 15%;
  }
}

@media (max-width: 600px){
  .start-page-last-btn{
    margin-left: 10%;
  }
}

@media (max-width: 560px){
  .start-page-last-btn{
    margin-left: 0;
    margin-top: 30px;
  }

  .start-page-last-bar-text{
    text-align: center;
    margin-left: 0;
    display: block;
  }

  .start-page-hero-content{
    padding-left: 0;
  }
}

@media (max-width: 420px){
	.start-page-btn-group{
    display: block;
  }

  .start-page-cta-button1{
    margin: 0 0 0 12px 
  }

  .start-page-cta-button2{
    height: 73px;
  }

  .start-page-hero{
    padding: 20px 20px 120px;
  }

  .start-page-h2{
    font-size: 20px;
  }

  .st_div_fontUP2{
    font-size: 15px;
    padding: 0 0 0px;
  }

  .st_div_fontUP3{
    font-size: 15px;
    padding: 0 0 0px;
  }
  .start-page-nav-wrapper{
    display: none;
  }
  .start-page-nav-wrapper-400 {
    width: 150px;
    text-align: center;
    margin-top: 10px;
    display: flex;
  }
  .start-page-triangle-400{
    display: none;
  }
  .start-page-dropdown-menu-400 {
    display: block;
    position: absolute;
    top: 100%;
    left: -115px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    min-width: 100px;
    z-index: 10;
  }
}

@media (max-width: 360px){
  .start-page-hero-h2 {
    font-size: 40px;
    line-height: 1.2;
  }

  .start-page-mediknocks-main {
    font-size: 45px;
  }

  .start-page-hero {
    padding: 20px 20px 50px;
  }

  .start-page-last-bar-text-first{
    font-size: 20px;
  }
  .start-page-last-bar-text-sec{
    font-size: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
    /*테블릿 세로*/
}

@media (min-width: 992px) and (max-width: 1199px) {
    /*테블릿 가로*/
}
