.diagnosis-alert-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.di_modal-content {
  background-color: #fff;
  border-radius: 15px;
  flex-direction: column;
  height: 292px;
  padding: 2rem;
  width: 456px;
  box-shadow: 0px 0px 20px -5px #666;
}

.di_center {
  display: flow;
  text-align: center;
}

.warning-icon {
  height: 85px;
  width: 85px;
}

.di_modal-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 0px;
  text-align: center;
}

.di_modal-message {
  font-family: 'Actor', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.25px;
  text-align: center;
  margin-bottom: 2rem;
}

.di_ok-button {
  width: 367px;
  height: 53px;
  border: solid 4px #FF1E00;
  border-radius: 15px;
  background-color: #FFF;
  font-size: 28px;
  font-weight: bold;
  color: #FF1E00;
  cursor: pointer;
}