@import url('https://fonts.googleapis.com/css2?family=Actor&display=swap');

.payment-notification-popup {
  width: 100%;
  max-width: 510px;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 499px;
  height: 451px;
}

.popup-title {
  font-size: 28px;
  margin-bottom: 35px;
  margin-top: 0;
}

.payment-icon {
  height: 80px;
  margin-bottom: 20px;
  width: 80px;
  border: 6px solid #01b6cd;
  border-radius: 60px;
  padding: 10px;
}

.payment_amount {
  border: none;
  resize: none;
  color: #01B6CD;
  font-size: 28px;
  font-weight: bold;
}

.payment_description {
  color: #2f3637;
  font-size: 16px;
  margin-bottom: 60px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.cancel-button,
.paymeny_ok-button {
  font-family: 'Actor', sans-serif;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.cancel-button {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
}

.paymeny_ok-button {
  background-color: #01b6cd;
  border: none;
  color: #fff;
  width: 131px;
  height: 42px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;
}

.actor-font {
  font-family: 'Actor', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.25px;
  text-align: center;
}

.description {
  margin-bottom: 20px; /* 필요에 따라 조정 */
}
