/* Login.css */

input::-ms-reveal,
input::-ms-clear {
    display : none;
}

.login-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.remember-me {
    display: flex;
    align-items: center;
}

.div-11 {
    color: #01b6cd;
    cursor: pointer;
}

.div-13 {
    margin-right: 5px;
}

.LoginPage_div {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center
}

.LoginPage_div-2 {
    align-self: center;
    display: flex;
    width: 100%;
    max-width: 1383px;
    gap: 20px;
    text-align: center;
    padding: 0 20px
}

@media (max-width:991px) {
    .LoginPage_div-2 {
        max-width: 100%;
        flex-wrap: wrap
    }
}

.LoginPage_div-3 {
    color: #000;
    flex-grow: 1;
    flex-basis: auto;
    text-align: left;
    margin: auto 0;
    font: 40px Inter, sans-serif
}

.LoginPage_div-4 {
    display: flex;
    gap: 20px;
    font-size: 24px;
    justify-content: space-between
}

.LoginPage_div-5 {
    color: #000;
    font-family: Inter, sans-serif;
    margin: auto 0
}

.LoginPage_div-6 {
    font-family: Inter, sans-serif;
    border-radius: 10px;
    background-color: #01b6cd;
    color: #fff;
    justify-content: center;
    padding: 13px 30px
}

@media (max-width:991px) {
    .LoginPage_div-6 {
        padding: 0 20px
    }
}

.LoginPage_div-7 {
    background-color: #F1F9FC;;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #5e5e5e;
    justify-content: center;
    padding: 4%;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.div {
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    width: 440px;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 78px 0 78px;
    padding: 48px 60px 21px;
    min-height: 500px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

@media (max-width:991px) {
    .div {
        margin: 40px 0;
        padding: 0 20px
    }
}

.div-2 {
    display: flex;
    width: 588px;
    max-width: 87%;
    flex-direction: column;
}

.div-3 {
    color: #000;
    text-align: center;
    align-self: center;
    font-size: 40px;
    font-weight: 700
}

@media (max-width:991px) {
    .div-3 {
        font-size: 40px;
        font-weight: 700
    }
}

.div-4 {
    text-align: center;
    font-family: Actor, sans-serif;
    align-self: center;
    margin-top: 5px;
    font-size: 20px
}

.div-5 {
    padding-top: 40px
}

.password-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px
}

.LoginWelComeImg{
    width: 108%;
}

.div-5,
.div-8 {
    align-self: start;
    display: flex;
    margin-top: 30px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #ccc
}

@media (max-width:991px) {
    .div-5,
    .div-8 {
        margin-top: 40px;
        width: 100%
    }
}

.lg_img {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 40px;
    height: 40px;
}

.div-6,
.div-9 {
    font-family: Actor, sans-serif;
    width: 100%;
    border: none;
    outline: none;
    padding-right: 30px;
    background: transparent;
}

.div-7,
.div-10 {
    border-color: rgba(201, 201, 201, 1);
    border-style: solid;
    border-width: 1px;
    background-color: #c9c9c9;
    margin-top: 4px;
    height: 1px
}

.div-11 {
    color: #01b6cd;
    background-color: #fff;
    text-align: right;
    align-self: end;
    font: 16px Actor, sans-serif;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    align-self: center;
}

.div-12 {
    align-self: start;
    display: flex;
    margin-top: 5px;
    gap: 10px;
    font-size: 13px
}

.div-13 {
    border-radius: 3px;
    border-color: rgba(201, 201, 201, 1);
    border-style: solid;
    border-width: 1px;
    width: 24px;
    height: 24px
}

.div-14 {
    cursor: pointer;
    font-family: Actor, sans-serif;
    margin: auto 0;
    font-size: 16px;
    padding-left: 5px;
    color: #2F3637;
}

.div-15 {
    border-radius: 10px;
    background-color: #01b6cd;
    margin-top: 41px;
    align-items: center;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    justify-content: center;
    padding: 16px 60px;
    font-size: 24px;
    width: 100%;
    border: none;
    opacity: .5;
    cursor: not-allowed
}

.div-15.active {
    opacity: 1;
    cursor: pointer
}

@media (max-width:991px) {
    .div-15 {
        margin-top: 40px;
        white-space: initial;
        padding: 0 20px
    }
}

.div-16 {
    color: #01b6cd;
    text-align: center;
    font-family: Actor, sans-serif;
    align-self: center;
    margin-top: 23px
}

.btnClear,
.toggle-password {
    width: 30px;
    background: none;
    border: none;
    font-size: 30px;
}

.toggle-password {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkEmail {
    font-size: 16px
}

.sign-up {
    color: rgba(94, 94, 94, .7);
    text-align: center;
    align-self: center;
    margin-top: 25px;
    margin-bottom: 15px;
    color: #2F3637;
}

.sign-up_text {
    color: #01B6CD;
    cursor: pointer;
    font-weight: bold;
}

.div-error,
.div-error-pass {
    min-height: 20px;
    font-size: 12px;
    margin-top: 5px
}

.div-5.error,
.div-8.error {
    border-color: red
}

.div-5.error input,
.div-8.error input {
    color: red
}

.input-container {
    position: relative;
    margin-bottom: 20px
}

.input-container.error .div-5,
.input-container.error .div-8 {
    border-bottom: 1px solid red
}


.input-wrapper {
    position: relative;
    width: 100%;
    display: flex;

}

.input-wrapper input {
    transition: border-color 0.3s ease;
    font-size: 20px;
}

.div-6::placeholder,
.div-9::placeholder{
    font-size: 20px;
    color: #9CABAD;
}

.input-wrapper input:focus {
    border-color: #01b6cd
}

.input-filled input {
    color: #000
}

.input-container.error .div-5,
.input-container.error .div-8 {
    border-bottom: 2px solid red
}

.error-text {
    color: red;
    font-size: 16px;
    margin-bottom: 8px;
    height: 15px;
    line-height: 15px;
    overflow: hidden;
    width: 100%;
    height: 30px;
    text-align-last: right;
    margin-top: 5px;
}

.input-wrapper {
    position: relative;
    width: 100%
}

.input-wrapper input {
    transition: border-color 0.3s ease
}

.input-wrapper input:focus {
    border-color: #01b6cd
}

.input-focused .div-5,
.input-focused .div-8 {
    border-bottom: 2px solid #01b6cd
}

.input-container.error .div-5,
.input-container.error .div-8 {
    border-bottom: 2px solid red
}

.input-container.error input {
    color: #000;
}

.password-toggle-icon {
    width: 150%;
}

.div-9[type="password"] {
    font-size: 20px; /* 동그라미 크기를 20px로 설정 */
    letter-spacing: 3px; /* 동그라미 간격 조정 */
    font-family: text-security-disc; /* 커스텀 폰트 사용 */
    -webkit-text-security: disc; /* WebKit 브라우저용 */
}

.div-9[type="password"]:focus {
    outline: none;
    border-color: #01b6cd;
}

/* 비밀번호 입력 필드의 플레이스홀더 스타일 */
.div-9[type="password"]::placeholder {
    font-size: 16px;
    color: #9CABAD;
    letter-spacing: normal;
}

/* 비밀번호 표시/숨김 토글 버튼 스타일 */
.toggle-password {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggle-password img {
    width: 140%;
}

/* 비밀번호 입력 필드 컨테이너 스타일 */
.input-container {
    position: relative;
    margin-bottom: 20px;
}

/* 에러 메시지 스타일 */
.error-text {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

/* 입력 필드가 채워졌을 때의 스타일 */
.input-filled .div-9[type="password"] {
    color: #000;
}

/* 입력 필드에 포커스가 있을 때의 스타일 */
.input-focused .div-8 {
    border-bottom: 2px solid #01b6cd;
}

/* 에러 상태일 때의 스타일 */
.input-container.error .div-8 {
    border-bottom: 2px solid red;
}

.input-container.error .div-9[type="password"] {
    color: red;
}

/* 기존 CSS 코드는 유지하고 아래 스타일을 추가합니다 */

/* 체크박스 컨테이너 스타일링 */
.remember-me {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 30px; /* 체크박스 크기 + 여백 */
    cursor: pointer;
    font-size: 16px;
    user-select: none;
}

/* 기본 체크박스 숨기기 */
.remember-me input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* 커스텀 체크박스 스타일 */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #9CABAD;
    border-radius: 4px;
}

/* 호버 시 스타일 */
.remember-me:hover input ~ .checkmark {
    background-color: #f0f0f0;
}

/* 체크된 상태의 스타일 */
.remember-me input:checked ~ .checkmark {
    background-color: #fff;
}

/* 체크 마크 (틱) 스타일 */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #01b6cd;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* 체크된 상태에서 체크 마크 표시 */
.remember-me input:checked ~ .checkmark:after {
    display: block;
}

/* "Remember me" 텍스트 스타일 */
.remember-me-text {
    margin-left: 8px;
    color: #5e5e5e;
}