@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2&display=swap');

.stop-treatment-modal {
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: auto;
  max-width: 500px;
  padding: 30px;
  width: 520px;
  height: 326px;
  box-shadow: 0px 0px 20px 0px #ccc;
}

.sad-icon {
  border: 6px solid #01b6ce;
  border-radius: 60px;
  height: 84px;
  margin-bottom: 20px;
  padding: 7px;
  width: 84px;
  margin-top: 20px;
}

.modal-text {
  color: #FF1E00;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 50px;
}

.modal-buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.st_cancel-button{
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;
  height: 42px;
  padding: 10px 20px;
  transition: background-color .3s;
  width: 131px;
  background-color: #FFFFFF;
  border: solid 1px #A8A7A7;
  color: #6B7677;
  font-weight: bold;
}

.confirm-button{
  border: 1px solid #01B6CD;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  height: 42px;
  padding: 10px 20px;
  transition: background-color .3s;
  width: 131px;
  color: #fff;
}

.cancel-button {
  background-color: #f0f0f0;
  color: #333;
}

.confirm-button {
  background-color: #00CED1;
  color: white;
}

.cancel-button:hover {
  background-color: #e0e0e0;
}

.confirm-button:hover {
  background-color: #01B6CD;
}

.confirm-button.loading-button {
  opacity: 0.7;
  cursor: not-allowed;
  position: relative;
}

.confirm-button .button-text {
  display: inline-block;
  transition: opacity 0.2s;
}

.confirm-button .button-text.loading {
  opacity: 0;
}

.confirm-button .loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spinner-spin 1s linear infinite;
}

@keyframes spinner-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}