/* SignUp_Main.css */

/* Base styles */
.su-body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

/* Layout */
.signup-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.content-container {
  background-color: #F1F9FC;
  display: flex;
  align-items: center;
  font-size: 19px;
  color: #5e5e5e;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: left;
  overflow-y: auto;
}

.next-button.disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.next-button.disabled:hover {
  background-color: #cccccc;
}

/* Navigation */
.fs0_topNaviBar {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  z-index: 1;
  box-shadow: 0 2px 4px #0000001a;
}

.nav-menu {
  display: flex;
  padding-right: 1%;
}

/* Buttons */
.login-button,
.next-button,
.previous-button,
.submit-button,
.ok-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
  height: 40px;
  transition: background-color 0.3s ease;
}

.login-button,
.next-button,
.submit-button,
.ok-button {
  background-color: #01b6cd;
  color: white;
}

.next-button {
  width: 60%;
  margin-top: 20px;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
}

.submit-button {
  width: 60%;
  padding: 12px;
  font-size: 28px;
  font-weight: bold;
  width: 387px;
  height: 61px;
  border-radius: 15px;
  margin-top: 30px;
}

.next-button:hover,
.submit-button:hover {
  background-color: #019aad;
}

.previous-button {
  background-color: #f5f5f5;
  color: #333;
}

/* Form container */
.signup-form-container {
  background-color: white;
  display: flex;
  margin-bottom: -44px;
  width: 389px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 30px;
  padding: 25px 100px;
  box-shadow: 2px 2px 30px -10px;
}

.signup-form-container.step-2 { width: 388px;  }
.signup-form-container.step-3 { height: auto; }
.signup-form-container.step-4 { height: 500px;  width: 400px; padding: 40px 160px; }
.signup-form-container.step-5 { height: 680px; width: 430px;}
.signup-form-container.step-6 {
  height: 780px;
  width: 400px;
  padding: 3% 110px;
}
.signup-form-container.step-7 {   
  height: 449px;
  width: 667px;
}

/* Header container styles */
.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding-top: 9px;  /* 뒤로 가기 버튼 높이만큼 상단 패딩 추가 */
}

.back-arrow {
  position: absolute;
  left: -75px;
  font-size: 24px;
  background-size: contain;
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.left{
  left: 10px;
}



/* Typography */
.su_h2 {
  color: rgba(0, 0, 0, 1);
  text-align: center;
  margin-top: 40px;
  font-size: 45px;
  font-weight: bold;
}

.subtitle {
  color: #5e5e5e;
  text-align: center;
  font-size: 12px;
}

/* Utility classes */
.bottom { margin-bottom: 70px; }
.top_25 { padding-top: 25px; }
.top_50 { padding-top: 50px; }
.bottom_50 { padding-bottom: 50px; }
.margin_25 { margin-top: 25px; }
.button_center { 
  text-align-last: center; 
}

.margin_20p{
  margin-top: 20%;
}

/* Error handling */
/*
.error-text {
  color: red;
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 8px; 
  height: 15px; 
  line-height: 15px; 
  overflow: hidden;
  width: 320px;
  height: 30px;
}*/

.error-container {
  height: 15px; 
  margin-bottom: 8px; 
}

/* Responsive design */
@media (max-width: 768px) {
  .signup-form-container {
    width: 90%;
    padding: 20px;
  }

  .content-container {
    padding: 20px;
    height: auto;
  }

  .next-button{
    width: 100%;
  
}

.submit-button {
  margin-top: -40px; /* 상단 여백을 줄임 */
  width: 100%;
}
}