.NTFCall_div {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  color: #5e5e5e;
  box-shadow: 0px 4px 20px 0px #2E7C9A33;
  margin: 10px;
}
@media (max-width: 991px) {
  .NTFCall_div {
    max-width: 100%;
    flex-wrap: wrap;
    padding-left: 20px;
    margin-top: 40px;
  }
}
.NTFCall_div-2 {
  background-color: #c9c9c9;
  border-radius: 50%;
  align-self: stretch;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  width: 75px;
  justify-content: center;
  height: 75px;
  margin: auto 0;
  padding: 0 23px;
  font: 400 24px Actor, sans-serif;
}
@media (max-width: 991px) {
  .NTFCall_div-2 {
    white-space: initial;
    padding: 0 20px;
  }
}
.NTFCall_icon_img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin: 10px;
}

.NTFCall_div-3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  color: #000;
  margin: auto 0;
  padding: 0 1%;
  gap: 20px;
  width: 230px;
}
.NTFCall_div-4 {
  font: 400 16px Adamina, sans-serif;
}
.NTFCall_div-5 {
  font-size: 16px;
  font-weight: bold;
}
.NTFCall_div-6 {
  align-items: start;
  align-self: stretch;
  border-radius: 10px;
  font: 300 12px Assistant, sans-serif;
  height: 24px;
  resize: none;
  width: 252px;
  border: 2px solid #C9C9C9;
  background-color: #fff;
  border: 2px solid #C9C9C9;
  padding: 10px;
  overflow-y: auto;
}

.NTFCall_div-6::-webkit-scrollbar {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #D3D8D9;
}

.NTFCall_div-6::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #2E7C9A;
}

@media (max-width: 991px) {
  .NTFCall_div-6 {
    max-width: 100%;
    padding-right: 20px;
  }
}
.NTFCall_div-7 {
  background-color: #01b6cd;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  height: 73px;
  width: 53px;
  margin-left: 22px;
  cursor: pointer;
}
@media (max-width: 991px) {
  .NTFCall_div-7 {
    font-size: 40px;
    white-space: initial;
    padding: 0 20px 0 23px;
  }
}
.NTFCall_img {
  position: absolute;
  inset: 0;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.NTFCall_div-8 {
  position: relative;
}

.NTFCall_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.NTFCall_dept {
  font-weight: 600;
  font-size: 16px;
  color: #333;
}

.NTFCall_info {
  font-size: 14px;
  color: #666;
}

.NTFCall_symptom {
  font-size: 12px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.NTFCall_button {
  background-color: #45b7e5;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.NTFCall_button:hover {
  background-color: #3a9ac5;
}

.NTFCall_div-7 {
  background-color: #01b6cd;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  height: 73px;
  width: 53px;
  margin-left: 22px;
  cursor: pointer;
  position: relative;
  transition: opacity 0.3s;
}

.NTFCall_div-7.NTFCall_loading-button {
  opacity: 0.7;
  cursor: not-allowed;
}

.NTFCall_div-7 .NTFCall_button-text {
  display: inline-block;
  transition: opacity 0.2s;
}

.NTFCall_div-7 .NTFCall_button-text.NTFCall_loading {
  opacity: 0;
}

.NTFCall_div-7 .NTFCall_loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border: 3px solid #ffffff;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: NTFCall_spin 1s linear infinite;
}

@keyframes NTFCall_spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@media (max-width: 991px) {
  .NTFCall_div-7 {
    font-size: 40px;
    white-space: initial;
    padding: 0 20px 0 23px;
  }
  
  .NTFCall_div-7 .NTFCall_loading-spinner {
    width: 30px;
    height: 30px;
  }
}