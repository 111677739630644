@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Figtree', sans-serif;
}

.step5-container {
  width: 90%;
  max-width: 600px;
  margin-top: -30px;
  padding: 20px;
  position: relative;
  text-align: -webkit-center;
}

.st5_back-arrow {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 24px;
  cursor: pointer;
}

.st5_su_h2 {
  font-family: 'Figtree', sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 10px;
  color: #2F3637;
}

.subtitle {
  text-align: center;
  color: #2F3637;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 50px;
}

.st5_file-upload-area {
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  background-color: #ededed;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  min-width: 310px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 105px 80px;
}

.st5_file-upload-area p {
  color: #9CABAD;
  margin: 0;
  font-size: 20px;
  font-family: 'Figtree', sans-serif;
}

.drag-active {
  border-color: #01b6cd;
  background-color: rgba(1, 182, 205, 0.1);
}

.st5_preview-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.st5_preview-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.st5_file-preview {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.st5_file-preview img {
  max-width: 380px;
  max-height: 280px;
  object-fit: contain;
}

.st5_file-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -5px;
  width: 100%;
  max-width: 520px;
  margin: 0 auto 40px auto;
}

.st5_upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #01b6cd;
  border: 2px solid #01b6cd;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Figtree', sans-serif;
  min-width: 120px;
  margin-left: auto;
}

img.st5_upload-icon1 {
  width: 40px;
  height: 40px;
}

.st5_upload-button:hover {
  background-color: #aacace;
}

.st5_upload-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  margin-bottom: 3px;
}

.st5_file-info {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  max-width: 60%;
  overflow: hidden;
}

.st5_file-info span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.st5_remove-file {
  background: none;
  border: none;
  color: #ff0000;
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
  padding: 0;
  flex-shrink: 0;
}

.st5_error-message {
  color: #ff0000;
  margin-top: 10px;
  text-align: center;
}

.st5_next-button {
  width: 55%;
  background-color: #01b6cd;
  color: white;
  border: none;
  padding: 15px 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 25px;
  font-family: 'Figtree', sans-serif;
}

.st5_next-button:hover {
  background-color: #018fa3;
}

.st5_next-button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.st5_file-upload-area-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.step5-container .st5_file-actions .st5_upload-button.uploaded,
.step5-container .st5_file-actions .st5_upload-button:disabled {
  background-color: #cccccc !important;
  color: #666666 !important;
  border-color: #cccccc !important;
  cursor: not-allowed !important;
}

.step5-container .st5_file-actions .st5_upload-button.uploaded:hover,
.step5-container .st5_file-actions .st5_upload-button:disabled:hover {
  background-color: #cccccc !important;
  color: #666666 !important;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .step5-container {
    padding: 10px;
  }

  .st5_file-upload-area {
    padding: 20px;
  }

  .st5_file-actions {
    max-width: 100%;
    padding: 0 5px;
  }

  .st5_file-info {
    max-width: 50%;
  }

  .st5_upload-button {
    min-width: 100px;
  }
}