.rpc-ForgetPasswordPage_div {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  font-family: Inter, sans-serif;
}

.rpc_contents{
display: flow;
height: 100%;
align-content: center;
text-align: -webkit-center;
}

.rpc_check_img{
  align-content: center;
  width: 200px;
  height: auto;
}

.rpc-ForgetPasswordPage_content {
  max-width: 525px;
  width: 100%;
  padding: 20px;
  text-align: center;
}

.rpc-ForgetPasswordPage_div-8 {
  color: #000;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 0px;
}

.rpc-ForgetPasswordPage_div-9 {
  color: #5e5e5e;
  font-size: 20px;
  margin-bottom: 70px;
}

.rpc-ForgetPasswordPage_div-3 {
  color: #5e5e5e;
  font-size: 20px;
  margin-bottom: 10px;
}

.rpc-ForgetPasswordPage_div-10 {
  margin-bottom: 0;
}

.rpc-userId {
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 16px;
}

.rpc-ForgetPasswordPage_div-13 {
  background-color: #ffffff;
  border: none;
  color: #01b6cd;
  cursor: pointer;
  font-size: 20px;
  text-decoration: underline;
}

.rpc-ForgetPasswordPage_div-14 {
  margin-top: 20px;
  display: inline-flex;
  align-items: center;
}

.rpc-a {
  color: #9CABAD;
  text-decoration: none;
  font-size: 14px;
  padding-right: 40px;
}

#validation-message {
  display: none; /* 기존 validation message 숨김 */
}

.rpc_progress-container {
  width: 100%;
  max-width: 200px;
  margin-bottom: 30px;
}

.fs0_topNaviBar {
  width: 100%;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.logo-image {
  height: 40px;
  width: auto;
}

@media (max-width: 991px) {
  .rpc-ForgetPasswordPage_content {
      padding: 20px;
  }
  
  .rpc-ForgetPasswordPage_div-8 {
      font-size: 20px;
  }
  
  .rpc-ForgetPasswordPage_div-9 {
      font-size: 12px;
  }
  
  .rpc-userId {
      font-size: 14px;
  }
  
  .rpc-ForgetPasswordPage_div-13 {
      font-size: 14px;
  }
}

.rpc_content_container{
  width: 75%;
  display: flow;
  align-content: center;
  margin: 0px 65px;
}

.rpc_input-group {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 2px solid #ccc;
  width: 100%;
}

.rpc_su_login_email.focused {
  border-color: #01b6cd;
}

.rpc_su_login_email.error {
  border-color: #fa5252;
}

.rpc_lg_img_rp {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 40px;
  height: 40px;
}

.rpc_lg_img_rp_back {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
  height: 24px;
}

.rpc_su_input_email {
  border: none;
  outline: none;
  width: 100%;
  font-size: 20px;
}

.rpc_su_input_email::placeholder {
  font-size: 20px;
  color: #9CABAD;
}

.rpc_btnClear {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #999;
}

.rpc_error-container {
  margin-top: 5px;
  height: 20px;
}

.rpc_error-text_rp {
  /* color: #fa5252;
  font-size: 14px; */
  color: red;
  font-size: 16px;
  margin-bottom: 8px;
  height: 15px;
  line-height: 15px;
  overflow: hidden;
  width: 100%;
  height: 30px;
  text-align-last: right;
  margin-top: 5px;
}
