@font-face {
  font-family: 'ABeeZee';
  src: url('fonts/ABeeZee-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

.Prev_modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.Prev_modal-content {
  width: 800px;
  max-width: 810px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  font-family: 'ABeeZee', Arial, sans-serif;
  overflow: hidden;
  position: relative;
}

.Prev_loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.Prev_loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.Prev_title {
  font-family: 'ABeeZee', Arial, sans-serif;
  font-style: italic;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.Prev_pdf-viewer-container {
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  border: 1px solid #e0e0e0;
  margin-bottom: 20px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.Prev_button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.Prev_cancel-button, .Prev_send-button {
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

.Prev_cancel-button {
  background-color: #f1f3f5;
  color: #495057;
}

.Prev_cancel-button:hover:not(:disabled) {
  background-color: #e9ecef;
}

.Prev_send-button {
  background-color: #17a2b8;
  color: white;
}

.Prev_send-button:hover:not(:disabled) {
  background-color: #138496;
}

.Prev_cancel-button:active:not(:disabled), .Prev_send-button:active:not(:disabled) {
  transform: scale(0.98);
}

.Prev_cancel-button:disabled, .Prev_send-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.Prev_error-message {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  text-align: center;
}

.Prev_pdf-viewer-container::-webkit-scrollbar {
  display: none;
}

.Prev_pdf-viewer-container .rpv-core__viewer {
  width: 100%;
  height: auto;
}

.Prev_pdf-viewer-container .rpv-core__page-layer {
  display: flex;
  justify-content: center;
  --scale-factor: 1.3;
  position: relative;
  height: 513px !important;
  width: 100% !important;
  overflow-y: scroll;
}

.Prev_pdf-viewer-container .rpv-core__page-layer::-webkit-scrollbar {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #D3D8D9;
}

.Prev_pdf-viewer-container .rpv-core__page-layer::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #2E7C9A;
}

.Prev_pdf-viewer-container .rpv-core__page-layer

.Prev_pdf-viewer-container .rpv-core__page-layer canvas {
  max-width: 100%;
  height: auto;
}

.Prev_pdf-viewer-container .rpv-core__viewer {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.Prev_pdf-viewer-container .rpv-core__viewer::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.Prev_pdf-viewer-container .rpv-core__inner-pages {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.Prev_pdf-viewer-container .rpv-core__inner-pages::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}