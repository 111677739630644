@import url('https://fonts.googleapis.com/css2?family=Adamina&family=Baloo+Paaji+2&display=swap');

.income-page {
  font-family: 'Adamina', serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
}

.user-calls {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.bell-icon, .dollar-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.user-calls h3 {
  flex-grow: 1;
  margin: 0;
  font-family: 'Baloo Paaji 2', cursive;
  font-weight: 500;
}

.call-count {
  font-size: 24px;
  font-weight: bold;
  color: #ff5722;
}

.settlement-details {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.settlement-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.settlement-header h2 {
  flex-grow: 1;
  margin: 0;
  font-family: 'Baloo Paaji 2', cursive;
  font-weight: 600;
}

.year {
  font-size: 18px;
  color: #888;
}

.weekly-list, .daily-list {
  border-top: 1px solid #eee;
}

.weekly-item, .daily-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.weekly-item:hover, .daily-summary:hover {
  background-color: #f0f0f0;
}

.date-range, .date {
  flex: 2;
}

.amount {
  flex: 1;
  color: #4caf50;
  font-weight: bold;
}

.status {
  flex: 1;
  color: #767676;
  font-family: 'Adamina', serif;
}

.cases {
  flex: 1;
  color: #767676;
  font-family: 'Adamina', serif;
}

.arrow, .accordion-icon {
  font-size: 12px;
  color: #767676;
  transition: transform 0.3s ease;
}

.accordion-icon.expanded {
  transform: rotate(180deg);
}

.back-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #01B6CD;
  margin-right: 10px;
}

.week-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.week-range {
  font-weight: bold;
}

.total-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #e8f5e9;
  border-radius: 4px;
}

.total-label {
  font-weight: bold;
}

.total-amount {
  font-size: 24px;
  color: #4caf50;
}

.treatment-list {
  background-color: #f9f9f9;
  padding: 10px;
}

.treatment-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 10px;
  color: #767676;
}

.treatment-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.treatment-time, .treatment-amount, .treatment-patient-info {
  flex: 1;
}

.treatment-amount {
  color: #4caf50;
  font-weight: bold;
}

.treatment-patient-info {
  color: #666;
}

.weekly-item:hover .arrow,
.daily-summary:hover .accordion-icon {
  color: #01B6CD;
}

@media (max-width: 600px) {
  .weekly-item, .daily-summary, .treatment-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .weekly-item > span, .daily-summary > span, .treatment-row > span {
    margin-bottom: 5px;
  }

  .arrow {
    align-self: flex-end;
  }
}