.progress-container {
   display: flex;
   width: 100%;
   height: 8px;
   gap: 9px;
   z-index: 10;
   position: relative;
 }

.progress-container_Medical {
  display: flex;
  width: 100%;
  height: 8px;
  gap: 9px;
  z-index: 10;
  position: relative;
}
 
 .progress-bar {
   flex: 1;
   background: #B9C3C4;
   border-radius: 4px;
   opacity: 1;
   width: 92px;
   height: 8px;
 }
 
 .progress-bar.active {
   background: #01B6CD;
 }
 
 .back-arrow {
   position: absolute;
   left: -60px;
   top: 50%;
   transform: translateY(-50%);
   width: 45px;
   height: 45px;
   cursor: pointer;
   background-size: contain;
 }