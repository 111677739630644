.PrescriptionForm body {
  font-family: 'ABeeZee', Arial, sans-serif;
  background-color: #f0f0f0;
}

.Pr_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000080;
}

.Pr_content {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 810px;
  max-height: 90vh;
}

.Pr_close-button {
  width: 131px;
  height: 42px;
  background-color: #9CABAD;
  color: #ffffff;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
}

.Pr_submit-btn {
  width: 131px;
  height: 42px;
  background-color: #00bcd4;
  color: #ffffff;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
}

.prescription-form {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px #0000001a;
  font-size: 14px;
  margin: auto;
  max-width: 626px;
  padding: 40px;
  width: 100%;
  align-content: center;
}

.doctors-note {
  resize: none;
  width: 405px;
}

.Pr_form-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 65px;
}

.Pr_title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.Pr_section-title {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.Pr_content {
  transition: max-height 0.3s ease-in-out, height 0.3s ease-in-out;
  overflow: hidden;
}

.Pr_content.expanded {
  overflow-y: auto;
}

.Pr_content.expanded::-webkit-scrollbar {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #D3D8D9;
}

.Pr_content.expanded::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #2E7C9A;
}

.pr_buttonContainer {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  justify-content: center;
}

.Pr_form-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.Pr_left-label {
  color: #000000;
  font-size: 16px;
  margin-right: 10px;
  min-width: 120px;
}

.Pr_drug-label {
  font-size: 16px;
  font-weight: bold;
}

.Pr_label2 {
  color: #333;
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;
  top: -5px;
  width: 100%;
  margin-bottom: 5px;
  text-align: left;
}

.pr_drugName {
  width: 403px;
}

.pr_drugStrength {
  width: 168px;
}

.pr_dispense {
  width: 106px;
}

.Pr_content input[type="text"] {
  border: 1px solid #9CABAD;
  border-radius: 5px;
  color: #333;
  font-size: 16px;
  padding: 8px 10px;
  height: 19px;
}

.Pr_content input[type="number"],
.Pr_content textarea {
  border: 1px solid #9CABAD;
  border-radius: 5px;
  color: #333;
  font-size: 16px;
  padding: 8px 10px;
  height: 19px;
}

.Pr_checkbox-label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  color: #9CABAD;
}

.pr_checkbox {
  width: 17px;
  height: 17px;
}

.Pr_content input::placeholder,
.Pr_content textarea::placeholder {
  color: #9CABAD;
  font-size: 15px;
}

.Pr_required {
  color: red;
}

.Pr_error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.Pr_drug-section {
  margin-top: 20px;
}

.Pr_left-label {
  color: #000000;
  font-size: 16px;
  margin-right: 10px;
  min-width: 120px; /* 라벨의 최소 너비 설정 */
}

.Pr_left-label2 {
  color: #000;
  font-size: 16px;
  min-width: fit-content;
}

.two_container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.Pr_add-drug-btn,
.Pr_remove-drug-btn {
  background-color: #fff;
  border: none;
  color: #2E7C9A;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  padding-left: 20px;
}

.pr_btnImage{
  width: 16px;
  height: auto;
  margin-right: 5px;
}

.Pr_add-drug-btn:hover {
  background-color: #00bcd4;
  color: #fff;
}

.Pr_submit-btn:hover {
  background-color: #008ba3;
  color: #fff;
}

.Pr_remove-drug-btn {
    color: #ea3434;
    font-weight: bold;
    font-size: 16px;
    float: right;
}

.Pr_remove-drug-btn:hover {
  background-color: #ea3434;
  color: #fff;
}

.Pr_plus-icon {
  margin-right: 10px;
}

.Pr_prescription-textarea {
  resize: vertical;
  min-height: 100px;
}

.Pr_refill-control {
  display: flex;
  align-items: center;
}

.Pr_refill-btn {
  background: none;
  border: 1px solid #ccc;
  font-size: 16px;
  cursor: pointer;
  color: #00bcd4;
  padding: 5px 10px;
  margin: 0 5px;
}

.Pr_refill-btn:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.Pr_refill-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  text-align: center;
}

@media (max-width: 768px) {
  .Pr_two-column {
    flex-direction: column;
  }

  .Pr_two-column > div {
    width: 100%;
  }
}

.Pr_form-row.Pr_diagnosis-name {
  flex-direction: column;
  align-items: flex-start;
}

.Pr_form-row.Pr_diagnosis-name input[type="text"] {
  width: 548px;
}

.Pr_content .doctors-note {
  resize: none;
  min-height: 100px;
}
.pr_width{
  width: 69%;
}

.pr_margin20{
  margin-right: 20px;
}

.pr_margin25{
  margin-right: 25px;
}

.pr_padding020{
  padding: 0 20px;
}

.pr_padding20200{
  padding: 20px 20px 0;
}

/* 새로 추가된 스타일 */
.Pr_form-group {
  padding: 20px;
  border-radius: 5px;
}

.Pr_strength-dispense-container {
  display: flex;
  gap: 20px;
}

.Pr_strength-container,
.Pr_dispense-container {
  display: flex;
  align-items: center;
}

.Pr_dispense-label {
  color: #000000;
  font-size: 16px;
  margin-right: 10px;
  min-width: 70px;
}

/* Add to PrescriptionForm.css */
.Pr_submit-btn.pr_loading-button {
  opacity: 0.7;
  cursor: not-allowed;
  position: relative;
}

.Pr_submit-btn .pr_button-text {
  display: inline-block;
  transition: opacity 0.2s;
}

.Pr_submit-btn .pr_button-text.pr_loading {
  opacity: 0;
}

.Pr_submit-btn .pr_loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: pr_spin 1s linear infinite;
}

@keyframes pr_spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}