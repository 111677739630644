@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&display=swap');
/* Step2_PersonalInfo.css */

.custom-date-picker {
  font-family: 'Figtree', sans-serif;
  width: 100%;
}

.custom-date-picker label {
  font-family: 'Figtree', sans-serif;
  font-size: 16px;
  margin-bottom: 8px;
  color: #5e5e5e;
}

.custom-date-input {
  position: relative;
  width: 69%;
}

.custom-date-input input {
  width: 115%;
  padding: 10px 0px;
  font-size: 16px;
  border: none;
  background-color: transparent;
  color: #5e5e5e;
  border-bottom: 2px solid #ccc;
  transition: border-color 0.3s;
  padding-bottom: 14px;
}

.custom-date-input input::placeholder {
  color: #9CABAD;
}

.custom-date-input input:focus {
  outline: none;
  border-bottom-color: #01B6CD;
}

.custom-date-input.has-error input {
  border-bottom-color: #ff0000;
}

.calendar-icon {
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 2;
}

.react-datepicker {
  font-family: 'Figtree', sans-serif;
}

.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.custom-header select {
  margin: 0 5px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}