.PatientInfo_div {
  background-color: #fff;
  font-family: Arial, sans-serif;
  max-width: 500px;
  margin: 0 auto;
  min-height: 100vh; /* 최소 높이를 뷰포트 높이로 설정 */
  display: flex;
  flex-direction: column;
  position: relative;
}

.PatientInfo_hr{
  width: 100%;
  color: #D3D8D9;
}

.PatientInfo_div-2 {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0; /* flex container 내에서 스크롤이 가능하도록 설정 */
}

.PatientInfo_combined-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 20px 75px;
  -ms-overflow-style: none;
  height: calc(100vh - 150px); /* 상단 여백과 하단 여백을 고려한 높이 계산 */
}

.PatientInfo_combined-content::-webkit-scrollbar {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #D3D8D9;
}

.event-question{
  font-weight: bold;
}

.event-answer{
  margin: 10px 17px 0;
}

.event-answer.no{
  color: #FA5252;
}

.PatientInfo_combined-content::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #2E7C9A;
}

.PatientInfo_box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.PatientInfo_img {
  width: 45px;
  height: 45px;
  margin-right: 13px;
}

.PatientInfo_div-4 {
  font-size: 28px;
  font-weight: 700;
  color: #2F3637;
}

.PatientInfo_div-7 {
  margin-bottom: 20px;
}

.PatientInfo_div-8 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.PatientInfo_div-10 {
  color: #2E7C9A;
  font-weight: 700;
  font-size: 20px;
}

.PatientInfo_div-13 {
  display: flex;
  align-items: baseline;
  padding: 5px 0;
}

.PatientInfo_div-14 {
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
}

.PatientInfo_div-15 {
  color: #9CABAD;
  font-size: 17px;
}

.PatientInfo_div-5 {
  font-weight: 700;
  font-size: 16px;
}

.PatientInfo_div-6 {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;
  width: -webkit-fill-available;
  resize: none;
  font-size: 14px;
  height: auto;
  min-height: 50px;
  max-height: 150px;
  overflow-y: auto;
}

.PatientInfo_div-6-2{
  min-height: 64px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;
  width: -webkit-fill-available;
  resize: none;
  font-size: 14px;
  height: auto;
  max-height: 150px;
  overflow-y: auto;
}

.PatientInfo_div-6-3{
  min-height: 39px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;
  width: -webkit-fill-available;
  resize: none;
  font-size: 14px;
  height: auto;
  max-height: 150px;
  overflow-y: auto;
}

.PatientInfo_div-6-4{
  min-height: 33px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;
  width: -webkit-fill-available;
  resize: none;
  font-size: 14px;
  height: auto;
  max-height: 150px;
  overflow-y: auto;
}

.PatientInfo_div-20 {
  font-weight: 700;
  font-size: 16px;
  color: #2E7C9A;
}

.PatientInfo_div-20-1 {
  margin-left: 5px;
  font-size: 13px;
  color: #2E7C9A;
  align-self: center;
}

.medicanConinatner {
  display: inline-flex;
  margin-bottom: 7px;
}

.flex_div-19 {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
}

.font_low {
  color: #2E7C9A;
  font-size: 13px;
  align-self: center;
}

.PatientInfo_div-24 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding-top: 10px;
}

.PatientInfo_div-22,
.PatientInfo_div-25,
.PatientInfo_div-26 {
  align-items: center;
  cursor: pointer;
  display: flex;
  width: 100%;
  height: auto;
  min-height: 45px;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  border-radius: 5px;
  padding: 10px 0;
}

.PatientInfo_div-22 {
  border: solid 2px #01B6CD;
  background: #FFF;
  color: #01B6CD;
}

.PatientInfo_div-25 {
  background-color: #01B6CD;
  color: #fff;
  border: none;
}

.PatientInfo_div-26 {
  background-color: #fff;
  border: 1.5px solid #FF1E00;
  color: #FF1E00;
  font-size: 16px;
  font-weight: 300;
}

.PatientInfo_div-25:disabled,
.PatientInfo_div-26:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

ul {
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: 0px;
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
}

@media screen and (max-width: 600px) {
  .PatientInfo_div {
    padding: 10px;
    height: 100vh;
  }
  
  .PatientInfo_combined-content {
    height: calc(100vh - 120px);
    padding: 15px 15px 60px;
  }
  
  .PatientInfo_div-4 {
    font-size: 24px;
  }
  
  .PatientInfo_div-14 {
    font-size: 18px;
  }
  
  .PatientInfo_div-15 {
    font-size: 15px;
  }
}

@media screen and (max-height: 500px) {
  .PatientInfo_combined-content {
    height: calc(100vh - 100px);
    padding: 10px 15px 50px;
  }
}