.emailV_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f8ff;
  }
  
  .emailV_card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    text-align: center;
    max-width: fit-content;
    width: 100%;
  }

  .emailV_successImage {
    width: 247px;  /* Adjust as needed */
    height: auto;
    margin-bottom: 1rem;
  }
  
  .emailV_clappingHands {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .emailV_title {
    color: #333;
    margin-bottom: 3%;
    font-size: 48px;
    inline-size: max-content;
    font-weight: bold;
  }
  
  .emailV_status {
    color: #666;
    font-size: 20px;
    margin-bottom: 10%;
  }