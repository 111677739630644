.CompleteSetPassword_div {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    text-align: center;
    padding: 36px 0 80px;
}
.CompleteSetPassword_div-2 {
    display: flex;
    width: 100%;
    max-width: 1383px;
    gap: 20px;
    padding: 0 20px;
}
@media (max-width: 991px) {
    .CompleteSetPassword_div-2 {
    max-width: 100%;
    flex-wrap: wrap;
    }
}
.CompleteSetPassword_div-3 {
    color: #000;
    flex-grow: 1;
    flex-basis: auto;
    text-align: left;
    margin: auto 0;
    font: 40px Inter, sans-serif;
}
.CompleteSetPassword_div-4 {
    display: flex;
    gap: 20px;
    font-size: 24px;
    justify-content: space-between;
}
.CompleteSetPassword_div-5 {
    color: #000;
    font-family: Inter, sans-serif;
    margin: auto 0;
}
.CompleteSetPassword_div-6 {
    font-family: Inter, sans-serif;
    border-radius: 10px;
    background-color: #01b6cd;
    color: #fff;
    justify-content: center;
    padding: 13px 30px;
}
@media (max-width: 991px) {
    .CompleteSetPassword_div-6 {
    padding: 0 20px;
    }
}
.CompleteSetPassword_div-7 {
    background-color: #5e5e5e;
    align-self: stretch;
    min-height: 1px;
    margin-top: 23px;
    width: 100%;
}
@media (max-width: 991px) {
    .CompleteSetPassword_div-7 {
    max-width: 100%;
    }
}
.CompleteSetPassword_div-8 {
    color: #000;
    font: 600 48px Baloo Bhaijaan 2, -apple-system, Roboto, Helvetica,
    sans-serif;
    color: #000;
    font-size: 48px;
    font-weight: 700;
    text-align: center;
}
@media (max-width: 991px) {
    .CompleteSetPassword_div-8 {
    margin-top: 40px;
    font-size: 40px;
    }
}
.CompleteSetPassword_div-9 {
    color: #5e5e5e;
    margin-top: 17px;
    font: 16px Actor, sans-serif;
}
.CompleteSetPassword_div-10 {
    border-radius: 15px;
    background-color: #01b6cd;
    margin-top: 49px;
    width: 367px;
    max-width: 100%;
    align-items: center;
    color: #fff;
    white-space: nowrap;
    justify-content: center;
    padding: 19px 60px;
    border: none;
    font-size: 28px;
    cursor: pointer;
}
@media (max-width: 991px) {
    .CompleteSetPassword_div-10 {
    margin-top: 40px;
    white-space: initial;
    padding: 0 20px;
    }
}
.CompleteSetPassword_div-11 {
    display: flex;
    margin-top: 60px;
    gap: 9px;
    font-size: 16px;
    color: #5e5e5e;
}
@media (max-width: 991px) {
    .CompleteSetPassword_div-11 {
    margin-top: 40px;
    }
}
.CompleteSetPassword_img {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 15px;
    align-self: start;
}
.CompleteSetPassword_div-12 {
    font-family: Actor, sans-serif;
}

.CompleteSetPassword_span-1{
    font-weight: 600; 
    color: rgba(1,182,205,1);
}

.CompleteSetPassword_span-2{
    font-weight: 600;
}

