

@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&display=swap');
/* Step2_PersonalInfo.css */

body {
  font-family: 'Figtree', sans-serif;
}

.Step2_container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  transition: height 0.3s ease;
  min-height: 710px;
}

.Step2_container.has-errors {
  min-height: 760px;
}


.Step2_back-arrow {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 24px;
  cursor: pointer;
}

.Step2_title {
  font-family: 'Figtree', sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  margin-bottom: -2px; /* 기존 마진 유지 */
}

.Step2_subtitle {
  text-align: center;
  
  color: #666;
  font-size: 20px;
}

.Step2_profile-picture-container {
  position: relative;
  width: 140px;
  height: 140px;
  border: 5px solid #01B6CD;
  border-radius: 50%;
  margin: 0 auto 20px;
}


.Step2_required-indicator {
  position: absolute;
  left: calc(50% - 70px);  /* 프로필 사진 컨테이너의 절반 너비만큼 왼쪽으로 이동 */
  top: 0;
  color: red;
  font-size: 20px;
  font-weight: bold;
}

.Step2_profile-picture-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  
}



.Step2_profile-picture-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 50%;
  font-size: 14px;
  color: #FFFFFF;
}



.Step2_profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.Step2_camera-icon-overlay {
  position: absolute;
  bottom: -2px;
  right: -2px;
  background-color: #01B6CD;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Step2_camera-icon {
  width: 45px;
  height: 45px;
  filter: brightness(0) invert(1);
}

.Step2_input-section {
  margin-bottom: 15px;
}

.Step2_input-group {
  position: relative;
}

.Step2_input-group:not(:last-child) {
}

.Step2_input-wrapper {
  position: relative;
  border-bottom: 2px solid #ccc;
  transition: border-color 0.3s;
  height: 45px;
  align-content: center;
}

.Step2_input-wrapper:focus-within {
  border-bottom-color: #01B6CD;
}

.Step2_input-group.has-error .Step2_input-wrapper {
  border-bottom-color: #ff0000;
}

.Step2_input-field {
  width: 85%;
  padding: 6px 0;
  border: none;
  outline: none;
  font-size: 18px;
  background: transparent;
  margin-left: 45px;
  line-height: 20px;
}

.Step2_input-underline {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 0;
  left: 0;
  bottom: -2px; /* 입력 필드 바로 아래에 위치 */
  border-bottom: 2px solid #9CABAD;
}


.Step2_input-field_date {
  width: 100%;
  padding: 8px 0;
  border: none;
  outline: none;
  font-size: 20px;
  background: transparent;
}

.Step2_input-field::placeholder,
.Step2_input-field_date::placeholder {
  font-style: normal;
}

.Step2_input-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 45px;
  height: 45px;
}

.Step2_input-group.has-error {
  margin-bottom: 0;
}

.Step2_dr-person-icon {
  width: 45px;  /* 또는 원하는 크기 */
  height: 45px; /* 또는 원하는 크기 */
}

.Step2_error-message_profile {
  color: #ff0000;
  font-size: 16px;
  margin-top: -15px;
  text-align: center;
}

.Step2_error-message {
  color: #ff0000;
  font-size: 16px;
  margin-top: 4px;
  text-align: right;
}

.Step2_error-message-date{
  color: #ff0000;
  font-size: 16px;
  margin-top: 4px;
  min-inline-size: max-content;
  text-align: left;
}

.Step2_error-message_gender {
  color: #ff0000;
  font-size: 16px;
  margin-top: 4px;
  min-inline-size: max-content;
  text-align: left;
}

.Step2_date-gender {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
}

.Step2_date-of-birth {
  width: 60%;
  position: relative;
  z-index: 10;
}

.Step2_gender-select {
  width: 40%;
  position: relative;
  z-index: 10;
}

.Step2_date-of-birth.has-error .Step2_input-wrapper,
.Step2_gender-select.has-error .Step2_input-field_date {
  border-bottom-color: #ff0000;
}

.Step2_date-of-birth .Step2_input-wrapper,
.Step2_gender-select .Step2_input-field_date {
  border-bottom: 2px solid #ccc;
  transition: border-color 0.3s;
}

.Step2_custom-date-input {
  position: relative;
}

.Step2_date-input-field {
  width: 100%;
  padding-right: 25px;
}

.Step2_calendar-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.Step2_doctor-type-group {
  margin-bottom: 20px;
}

.Step2_doctor-type-label {
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;
}

.Step2_radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 16px;
}

.Step2_radio-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  outline: none;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}

.Step2_radio-input:checked {
  border-color: #01B6CD;
}

.Step2_radio-input:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: #01B6CD;
  border-radius: 50%;
}

.Step2_next-button {
  width: 55%;
  background-color: #01b6cd;
  color: white;
  border: none;
  padding: 15px 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 25px;
}

.Step2_next-button:hover {
  background-color: #019aad;
}


.Step2_next-button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Responsive design */
@media (max-width: 480px) {
  .Step2_container {
    width: 95%;
    padding: 15px;
  }

  .Step2_date-gender {
    flex-direction: column;
  }

  .Step2_date-of-birth,
  .Step2_gender-select {
    width: 100%;
    margin-bottom: 15px;
  }

  .Step2_input-underline {
    width: 95%; /* 모바일 화면에서는 약간 좁게 */
    left: 2.5%;
  }

}

.Step2_error-container {
  margin-top: 5px;
  min-height: 20px;
}

.Step2_error-text {
  color: red;
  font-size: 16px;
  text-align: right;
}