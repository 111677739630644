.lg_modal-overlay {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
 }
 
 .lg_modal-content {
    width: 423px;
   height: 280px;
   background-color: white;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   box-shadow: 0px 0px 20px -5px #666;
 }
 
 .lg_modal-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 59.92px;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
 }
 
 .lg_button-container {
   display: flex;
   gap: 50px;
 }
 
 .lg_modal-button {
  border-radius: 10px;
  cursor: pointer;
  font-family: Actor, sans-serif;
  font-size: 20px;
  font-weight: 400;
  height: 42.88px;
  line-height: 19.25px;
  text-align: center;
  width: 131px;
 }
 
 .lg_modal-button.cancel {
   border: 1px solid #ccc;
   background-color: white;
   color: #6B7677;
 }
 
 .lg_modal-button.confirm {
  background-color: #fff;
  border: 2px solid #ff1e00;
  color: #ff1e00;
}

.lg_modal-img{
  width: 80px;
  height: 80px;
}