.notification-container {
  position: relative;
}

.wcc-notification-button {
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

.notification-popover {
  position: absolute;
  top: 100%;
  right: 0;
  width: 483px;
  background-color: #2e7b9ab3;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  z-index: 1000;
  overflow: hidden;
  max-height: 361px;
  overflow-y: auto;
  -ms-overflow-style: none;
}

.notification-trigger {
  position: relative;
}

.notification-component {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  /* 추가 스타일 */
}

.notification-trigger:hover .notification-component {
  display: block;
}

.notification-popover::-webkit-scrollbar{
  display:none;
}

.notification-popover-header {
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: bold;
  background-color: #f8f8f8;
  color: #333;
  font-size: 14px;
}

.no-calls-message {
  padding: 16px;
  text-align: center;
  color: #666;
  font-style: italic;
}