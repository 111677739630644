html {
  overflow:hidden
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  font-size: 24px;
  font-weight: bold;
  padding-right: 86%;
}

.logo_1 {
  color: #007bff;
}

.logo_2 {
  color: #333;
}

.logout {
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}

.content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.sidebar {
  width: 250px;
  background-color: #f7f7f7;
  border-right: 1px solid #ddd;
  overflow-y: auto;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.user-calls-list {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f7f7f7;
  flex: 1;
}

.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.user-info {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f7f7f7;
}

.user-info h2 {
  margin: 0 0 10px;
}

.user-info p {
  margin: 5px 0;
}

.prescribe-btn, .impossible-treatment-btn {
  margin: 10px 0;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.prescribe-btn {
  background-color: #007bff;
  color: #fff;
}

.impossible-treatment-btn {
  background-color: #ff4d4d;
  color: #fff;
}
