/* Step6_SelfIntroduction.css 부분 */
.step6-container {
  width: 100%;
  padding-bottom: 60px;
}

.st6_title {
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #333;
  font-family: 'Figtree';
}

.st6_subtitle {
  color: #666;
  text-align: center;
  margin-bottom: 40px;
  font-size: 16px;
  font-family: 'Figtree';
}

.st6_input-group {
  margin-bottom: 20px;
}

.paddingBottom{
  padding-bottom: 40px;
}

.st6_input-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
  font-size: 16px;
  font-family: 'Figtree';
}

.st6_textarea {
  width: 475px;
  height: 180px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 14px;
  transition: all 0.3s ease;
  resize: none;
  overflow-y: auto;
  box-shadow: 0 0 2px -2px;
  font-family: 'Figtree';
}

.st6_textarea.initial {
  color: #888;
}

.st6_textarea.active {
  color: #000;
  border-color: #01B6CD;
}

.st6_textarea.error {
  border-color: #ff0000;
}

.st6_textarea:focus {
  outline: none;
  box-shadow: 0 0 2px -2px;
}

.st6_error-message {
  color: #ff0000;
  font-size: 16px;
  margin-top: 5px;
  float: right;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .signup-form-container {
    padding: 20px;
  }

  .st6_title {
    font-size: 44px;
  }

  .st6_subtitle {
    font-size: 14px;
  }

  .st6_textarea {
    height: 120px;
  }
}

/* 기존 CSS 스타일은 유지하고 새로운 스타일 추가 */

.st6_submit-button {
  background-color: #01B6CD;
  border: none;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  font-size: 28px;
  height: 61px;
  min-width: 387px;
  position: relative;
  transition: all .3s ease;
}

.st6_submit-button:hover {
  background-color: #01b5cdb0;
}

.st6_submit-button.st6_loading-button {
  opacity: 0.7;
  cursor: not-allowed;
}

.st6_button-text {
  display: inline-block;
  transition: opacity 0.2s;
}

.st6_button-text.st6_loading {
  opacity: 0;
}

.st6_loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: st6_spin 1s linear infinite;
}

@keyframes st6_spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* 추가적인 반응형 스타일 */
@media (max-width: 768px) {
  .st6_submit-button {
    min-width: 90px;
    height: 36px;
    font-size: 14px;
  }

  .st6_loading-spinner {
    width: 16px;
    height: 16px;
  }
}