.rp-ForgetPasswordPage_div {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    font-family: Inter, sans-serif;
}

.rp_contents{
  display: flow;
  height: 100%;
  align-content: center;
  text-align: -webkit-center;
}

.rp-ForgetPasswordPage_content {
    max-width: 525px;
    width: 100%;
    padding: 20px;
    text-align: center;
}

.rp-ForgetPasswordPage_div-8 {
    color: #000;
    font-size: 44px;
    font-weight: bold;
    margin-bottom: 10px;
}

.rp-ForgetPasswordPage_div-9 {
    color: #5e5e5e;
    font-size: 20px;
    margin-bottom: 70px;
}

.rp-ForgetPasswordPage_div-10 {
    margin-bottom: 0;
  }

.rp-userId {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 16px;
}

.rp-ForgetPasswordPage_div-13 {
    width: 100%;
    border-radius: 15px;
    background-color: #01b6cd;
    color: #fff;
    padding: 10px;
    border: none;
    font-size: 28px;
    cursor: pointer;
    margin-top: 40px;
    height: 62px;
    width: 388px;
}

.rp-ForgetPasswordPage_div-14 {
    margin-top: 20px;
    display: inline-flex;
    align-items: center;
}

.rp-a {
    color: #9CABAD;
    text-decoration: none;
    font-size: 14px;
    padding-right: 40px;
}

#validation-message {
    display: none; /* 기존 validation message 숨김 */
  }

.rp_progress-container {
    width: 100%;
    max-width: 200px;
    margin-bottom: 30px;
}

.rp_HeaderContainer{
    display: flex;
    justify-content: center;
    width: 38%;
}

.fs0_topNaviBar {
    width: 100%;
    padding: 10px 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.logo-image {
    height: 40px;
    width: auto;
}

@media (max-width: 991px) {
    .rp-ForgetPasswordPage_content {
        padding: 20px;
    }
    
    .rp-ForgetPasswordPage_div-8 {
        font-size: 20px;
    }
    
    .rp-ForgetPasswordPage_div-9 {
        font-size: 12px;
    }
    
    .rp-userId {
        font-size: 14px;
    }
    
    .rp-ForgetPasswordPage_div-13 {
        font-size: 14px;
    }
}

.rp_content_container{
    width: 75%;
    display: flow;
    align-content: center;
    margin: 0px 65px;
}

.rp_input-group {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 2px solid #ccc;
    width: 100%;
  }
  
  .rp_su_login_email.focused {
    border-color: #01b6cd;
  }
  
  .rp_su_login_email.error {
    border-color: #fa5252;
  }
  
  .rp_lg_img_rp {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 40px;
    height: 40px;
  }

  .rp_lg_img_rp_back {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 24px;
    height: 24px;
  }
  
  .rp_su_input_email {
    border: none;
    outline: none;
    width: 100%;
    font-size: 20px;
  }

  .rp_su_input_email::placeholder {
    font-size: 20px;
    color: #9CABAD;
  }
  
  .rp_btnClear {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #999;
  }
  
  .rp_error-container {
    margin-top: 5px;
    height: 20px;
  }
  
  .rp_error-text_rp {
    /* color: #fa5252;
    font-size: 14px; */
    color: red;
    font-size: 16px;
    margin-bottom: 8px;
    height: 15px;
    line-height: 15px;
    overflow: hidden;
    width: 100%;
    height: 30px;
    margin-top: 5px;
  }

  .rp_input-group {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 2px solid #ccc;
    width: 100%;
    transition: border-color 0.3s ease;
}

.rp_su_login_email.focused {
    border-color: #01b6cd;
}

.rp_su_login_email.error {
    border-color: red;
}

/* 새로운 valid-email 상태 스타일 */
.rp_su_login_email.valid-email {
    border-bottom: 2px solid #01b6cd;
}

/* 이메일 확인 상태 스타일 */
.rp_su_login_email.verification-sent {
    border-color: #00C823;
}

.rp_lg_img_rp {
    width: 45px;
    height: 45px;
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
}

.rp_su_input_email {
    border: none;
    outline: none;
    width: 100%;
    font-size: 20px;
    background: transparent;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.rp_su_input_email::placeholder {
    font-size: 20px;
    color: #9CABAD;
    font-style: normal;
}

.rp_su_input_email:disabled {
    background-color: #f0f0f0;
    color: #888;
    cursor: not-allowed;
}

.rp_btnClear {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #888;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.rp_error-container {
    margin-top: 5px;
    height: 20px;
    width: 105%;
    inline-size: 370px;
}

/* 에러 텍스트 스타일 업데이트 */
.rp_error-text_rp {
    color: #FF1E00;
    font-size: 16px;
    margin-top: 2px;
    line-height: 15px;
    text-align: left;
}

/* 새로운 안내 메시지 스타일 */
.rp_password-guidance {
    color: #6c757d;
    font-size: 16px;
    margin-top: 2px;
    line-height: 15px;
    width: 100%;
    text-align: left;
}

.rp_verification-sent {
    color: #00C823;
    font-size: 12px;
    margin-top: 2px;
    text-align: left;
}

/* Next 버튼 비활성화 상태 */
.rp-ForgetPasswordPage_div-13:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .rp_input-group {
        flex-direction: column;
        height: auto;
        gap: 10px;
    }

    .rp_su_input_email {
        width: 100%;
        padding-right: 40px;
    }

    .rp_btnClear {
        right: 5px;
    }
}

.rp-ForgetPasswordPage_div-13.rp-loading-button {
    opacity: 0.7;
    cursor: not-allowed;
    position: relative;
  }
  
  .rp-ForgetPasswordPage_div-13 .rp-button-text {
    display: inline-block;
    transition: opacity 0.2s;
  }
  
  .rp-ForgetPasswordPage_div-13 .rp-button-text.rp-loading {
    opacity: 0;
  }
  
  .rp-ForgetPasswordPage_div-13 .rp-loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    border: 3px solid #ffffff;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: rp-spin 1s linear infinite;
  }
  
  @keyframes rp-spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }