.mdm-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .mdm-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .mdm-title {
    font-family: 'Figtree', sans-serif;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 5px;
    text-align: center;
    color: black;
  }
  
  .mdm-subtitle {
    text-align: center;
    color: #666;
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .mdm-department-container {
    height: 250px;
    overflow-y: auto;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  
  .mdm-department-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 12px;
  }
  
  .mdm-department-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 5px solid #fff;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 5px #c8c8c8;
    width: 100px;
    height: 90px;
  }
  
  .mdm-department-button.active {
    border: solid 5px #01b6cd;
    color: #01b6cd;
  }
  
  .mdm-department-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
  }
  
  .mdm-department-name {
    font-size: 12px;
    text-align: center;
  }
  
  .mdm-button-center {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .mdm-cancel-button,
  .mdm-save-button {
    background-color: #01b6cd;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 0 10px;
  }
  
  .mdm-cancel-button {
    background-color: #ccc;
  }
  
  /* Scrollbar styles */
  .mdm-department-container::-webkit-scrollbar {
    width: 6px;
  }
  
  .mdm-department-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .mdm-department-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  
  .mdm-department-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .mdm-department-grid {
      grid-template-columns: repeat(4, 1fr);
    }
    
    .mdm-title {
      font-size: 28px;
    }
    
    .mdm-subtitle {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .mdm-department-grid {
      grid-template-columns: repeat(3, 1fr);
    }
    
    .mdm-title {
      font-size: 24px;
    }
    
    .mdm-subtitle {
      font-size: 12px;
    }
    
    .mdm-department-button {
      width: 80px;
      height: 70px;
    }
    
    .mdm-department-icon {
      width: 30px;
      height: 30px;
    }
    
    .mdm-department-name {
      font-size: 10px;
    }
  }