/* Import SendBird UIKit default CSS */
@import '@sendbird/uikit-react/dist/index.css';

/* Custom styles */
.app-container {
  display: flex;
  /* height: 100vh; */
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ddd;
}

.logo {
  font-size: 20px;
  font-weight: bold;
  color: #1c7ed6;
  flex-grow: 0;
  flex-basis: auto;
  width: auto;
  margin: auto 0;
  font: 40px Inter, sans-serif;
}

.logout {
  cursor: pointer;
  color: #ff6b6b;
  font-family: Inter, sans-serif;
  margin: auto 0;
}

.content {
  display: flex;
  flex: 1;
}

.sidebar {
  width: 250px;
  border-right: 1px solid #ddd;
  padding: 10px;
  overflow-y: auto;
}

.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tab {
  padding: 10px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  border-radius: 4px;
}

.tab.selected {
  background-color: #1c7ed6;
  color: white;
}

.channel-list {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

.channel-list div {
  cursor: pointer;
}

.channel-list div:hover {
}

.chat-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.info-section {
  width: 250px;
  border-left: 1px solid #ddd;
  padding: 10px;
}

.patient-info img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 20px;
}

.diagnose-button {
  width: 100%;
  padding: 10px;
  background-color: #1c7ed6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.diagnose-button:hover {
  background-color: #1971c2;
}
