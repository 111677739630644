.patient-history-pdf-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .patient-history-pdf-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    height: 52%;
    max-width: 976px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .pdf-viewer-container {
    flex-grow: 1;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .mediknocks-text {
    font-size: 24px;
    color: #888;
  }
  
  .mediknocks-logo {
    max-width: 150px;
    height: auto;
  }

  .Patient_footer{
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
  
  .PatientHistory_back-button {
    background-color: #FFF;
    border: none;
    border-radius: 10px;
    border: solid 2px #01B6CD;
    bottom: -40px;
    color: #fff;
    cursor: pointer;
    left: 50%;
    padding: 10px 20px;
    width: 169px;
    height: 45px;
    font-size: 20px;
    color: #01B6CD;
  }

  .PatientHistoryButton{
    border: none;
    background-color: #FFF;
  }
    
  /* Override default PDF viewer styles */
  .rpv-core__viewer {
    border: none !important;
  }
  
  .rpv-core__inner-pages {
    padding: 0 !important;
  }
  
  .rpv-core__inner-page {
    box-shadow: none !important;
  }
  
  .rpv-core__page-layer {
    border: none !important;
  }

  .rpv-default-layout__sidebar {
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }
  
  .rpv-default-layout__sidebar::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
  
  .rpv-core__inner-pages.rpv-core__inner-pages--vertical {
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }
  
  .rpv-core__inner-pages.rpv-core__inner-pages--vertical::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
  
  /* Ensure the sidebar is hidden */
  .rpv-default-layout__sidebar {
    display: none !important;
  }
  
  /* Adjust main content to take full width */
  .rpv-default-layout__main {
    width: 100% !important;
    left: 0 !important;
  }

  .rpv-default-layout__container {
    border: none !important;
  }

  .rpv-core__page-layer.rpv-core__page-layer--single{
    height: 350px !important;
    width: 714.336px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }

  .rpv-core__page-layer.rpv-core__page-layer--single::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
    border: none;
    background-color: #D3D8D9;
  }
  
  .rpv-core__page-layer.rpv-core__page-layer--single::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 50px;
    border: none;
    background-color: #2E7C9A;
  }