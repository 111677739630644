.RP-main-body{
  background-color: #ebeef7;
}

.RP-main-container {
  /* width: 100%; */
  max-width: 480px;
  margin: 0 auto;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  text-align: center;
  overflow-y: scroll;
  background-color: white;
  padding: 40px;
}

.RP-main-result-top{
  margin-bottom: 50px;
}

.RP-result-top{
  display: block;
}

.RP-Result-title-text{
  font-size: 29px;
  font-weight: bold;
  margin: auto;
}

.RP-Result-title-text2{
  font-size: 25px;
}

.RP-Result-sub-text-2{
  font-size: 12px;
  color: #666;
}

.RP-Result-grade-text{
  text-align: left;
  margin-bottom: 50px;
}

.RP-Result-grade-text-D{
  color: #E94953;
  font-weight: bold;
}

.RP-Result-grade-text-C{
  color: #FFBC2F;
  font-weight: bold;
}

.RP-Result-grade-text-B{
  color: #3CB54C;
  font-weight: bold;
}

.RP-Result-grade-text-A{
  color: #01B6CD;
  font-weight: bold;
}

.RP-Result-title-text2-bold{
  color: #01b6cd;
}

.RP-main-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.RP-Result-bottom-title-text{
  font-size: 25px;
  font-weight: 700;
}

.RP-btn-retry{
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #01b6cd;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 40px;
}

.RP-btn-retry:hover {
  background-color: #2e7c9a;
}

.RP-result-image{
  width: 80%;
}

.RP-store-budge-group{
  display: block;
}

.RP-cta-button1 {
  height: 50px;
  width: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.RP-cta-button2 {
  height: 70px;
  width: auto;
  margin-right: 10px;
}

/* p {
  font-size: 1.3rem;
} */

.RP-share-buttons {
  display: block;
  margin: 20px;
}

.RP-share-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
}

.RP-share-button1{
  width: 50px;
  margin: 25px 10px;
  cursor: pointer;
}

.RP-share-button2{
  width: 50px;
  margin: 25px;
  cursor: pointer;
}

.RP-share-button3{
  width: 45px;
  margin: 25px 10px; 
  cursor: pointer;
}

.RP-share-buttons{
  margin-left: 20px;
}
@media (max-width: 600px) {
  h2 {
    font-size: 1.5rem;
  }
  /* p {
    font-size: 1.1rem;
  } */
}
