html,
body {
    background-color: white;
    margin: -1px;
}

.logo_1 {
    font-weight: 600;
    color: rgba(1, 182, 205, 1);
}

.logo-2 {
    font-weight: 600;
}

.login {
    font-family: Inter, sans-serif;
    border-radius: 10px;
    background-color: #01b6cd;
    color: #fff;
    font-weight: 400;
    justify-content: center;
    padding: 13px 41px;
    border: none;
}

@media (max-width: 991px) {
    .login {
        white-space: initial;
        padding: 0 20px;
    }
}

.mainContainer_div {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
}

.img {
    object-fit: auto;
    object-position: center;
    width: 10%;
    height: auto;
}

.logo_3 {
    margin-top: 10px;
    width: 200px;
}

.footer_Style_0 {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .footer_Style_overflow {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

.fs0_topNaviBar {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    width: 99%;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.fs0_MenuContainer {
    display: flex;
    gap: 30px;
    font-size: 24px;
    white-space: nowrap;
    justify-content: space-between;
    color: #333;
    text-decoration: none;
    font-size: 16px;
    padding-right: 20px;
}

@media (max-width: 1000px) {
    .fs0_MenuContainer {
        display: none;
    }
}

.fs0_text {
    color: #666;
    font-family: Inter, sans-serif;
    font-weight: 500;
    margin: auto 0;
    position: relative;
    overflow: hidden;
}

.fs0_text::before {
    content: '';
    position: absolute;
    top: 90%;
    left: 50%;
    width: 0;
    height: 20px;
    background-color: black;
    transition: width 0.3s ease, left 0.3s ease;
    z-index: -1;
}

.fs0_text:hover::before {
    width: 100%;
    left: 0;
}
.fs0_text:hover {
    color: #666;
}

.fs0_logo_container {
    color: #000;
    flex-grow: 1;
    flex-basis: auto;
    text-align: left;
    font: 600 40px Inter, sans-serif;
    color: #000;
    flex-basis: auto;
    flex-grow: 1;
    font: 600 40px Inter, sans-serif;
    margin: auto 0;
    text-align: left;
    margin: 0 auto;
    padding: 0 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    border-bottom: 1px solid #E0E0E0;
}

.logo-image {
    height: 35px;
    display: flex;
    align-items: center;
}

.nav-menu {
    display: flex;
  align-items: center;
}

.login-button {
    background-color: #01b6cd;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #019aad;
  }