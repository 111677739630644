/* Step1_CreateAccount.css */
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Figtree', sans-serif;
}

.Step1_title {
  font-family: 'Figtree', sans-serif;
  font-size: 44px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  margin-bottom: -2px;
}

.success-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4CAF50;
  color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  z-index: 1000;
}

.success-message button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: white;
  color: #4CAF50;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.Step1_subtitle {
  text-align: center;
  color: #666;
  font-size: 20px;
}

.Step1_signup-form-container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  box-sizing: border-box;
}

.step1_email_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
}

.Step1_su_login {
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  position: relative;
  border-bottom: 2px solid #ccc;
}

.Step1_su_login_email {
  display: flex;
  align-items: center;
  width: calc(100% - 120px);
  position: relative;
  border-bottom: 2px solid #ccc;
}

.Step1_su_input_email {
  font-family: Arial, sans-serif;
  width: 175px;
  border: none;
  outline: none;
  background: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.Step1_su_input_password {
  font-family: Arial, sans-serif;
  width: 280px;
  border: none;
  outline: none;
  background: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.Step1_su_input_password:disabled,
.Step1_su_input_email:disabled {
  background-color: #f0f0f0;
  color: #888;
  cursor: not-allowed;
}

.Step1_lg_img_step1 {
  width: 45px;
  height: 45px;
}

.Step1_btnClear {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #888;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.Step1_password-guidance {
  color: #6c757d;
  font-size: 12px;
  margin-top: 2px;
  line-height: 15px;
  width: 100%;
}

.Step1_toggle-password {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: -5px;
  top: 53%;
  transform: translateY(-50%);
}

.Step1_password-toggle-icon {
  width: 45px;
  height: 45px;
}

.Step1_input-group {
  border-bottom: 2px solid #ccc;
  transition: border-color 0.3s ease;
}

/*.Step1_input-group.valid-email {
  border-bottom: 2px solid #01b6cd;
}*/

/* 비밀번호가 유효할 때 */
.Step1_input-group.valid-password {
  border-bottom: 2px solid #01b6cd;
}
.Step1_input-group.error {
  border-color: red;
}

.Step1_su_login_email.verification-sent {
  border-color: #00C823;
}

.Step1_su_login_email.verification-waiting {
  border-color: #00C823;
}

.Step1_error-container {
  height: 20px;
  margin-top: 5px;
  width: 105%;
  inline-size: 370px;
}

.Step1_correct-email {
  color: #01B6CD;
  font-size: 12px;
  margin-top: 2px;
}

.Step1_correct-password {
  color: #01B6CD;
  font-size: 12px;
  margin-top: 2px;
}

.Step1_error-text_step1 {
  color: red;
  font-size: 12px;
}

.Step1_su_input_password::placeholder,
.Step1_su_input_email::placeholder {
  font-style: normal;
}

.Step1_verification-button {
  background-color: white;
  border: 1px solid #01B6CD;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  color: #01B6CD;
  font-size: 14px;
  white-space: nowrap;
  height: 40px;
}

.Step1_verification-button:hover {
  background-color: #f0f0f0;
}

.Step1_verification-button.verified {
  background-color: #00C823;
  color: white;
  cursor: default;
}

.Step1_verification-button:disabled {
  background-color: white;
  border: 1px solid #666666;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  color: #666666;
  font-size: 14px;
  white-space: nowrap;
  height: 40px;
}

.Step1_verification-sent {
  color: #00C823;
  font-size: 12px;
  margin-top: 2px;
}

.Step1_verification-complete {
  color: #01B6CD;
  font-size: 12px;
  margin-top: 2px;
}

.Step1_input-group.Step1_su_login_email.verified-email {
  border-bottom: 2px solid #01B6CD;
}

.Step1_verification-waiting {
  color: #01B6CD;
  font-size: 12px;
  margin-top: 2px;
}

/* Responsive design */
@media (max-width: 768px) {
  .Step1_su_login {
    flex-direction: column;
    height: auto;
    gap: 10px;
  }

  .Step1_su_input_email {
    width: 100%;
    padding-right: 40px;
  }

  .Step1_verification-button {
    position: static;
    transform: none;
    margin-top: 10px;
    width: 100%;
  }

  .Step1_btnClear {
    right: 5px;
  }
}

.Step1_next-button {
  width: 100%;
  background-color: #01b6cd;
  color: white;
  border: none;
  padding: 15px 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 25px;
}

.padding25p {
  padding-top: 25%;
}

.step1_margin10p {
  margin-bottom: 10%;
}