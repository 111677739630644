


.step7-container {
  
  min-height: 300px;
  width: 100%;
  max-width: 400px;
  justify-content: space-between;
  top: -7vh;
}

.logo2 {
  width: 180px;
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.confirmation-title {
  font-family: 'Figtree', sans-serif;
  font-size: 34px;
  color: #333;
  margin: 0 0 10px 0;
  white-space: nowrap;
}

.confirmation-message {
  font-family: 'Figtree', sans-serif;
  font-size: 20px;
  color: #666;
  line-height: 1.5;
  white-space: nowrap;
}

.next-button {
  background-color: #01B6CD;
  color: white;
  font-family: 'Figtree', sans-serif;
  font-size: 28px;
  padding: 10px 0;
  border-radius: 15px;
  cursor: pointer;
  width: 100%;
  border: none;
  transition: background-color 0.3s ease;
  width: 387px;
  height: 61px;
}

.next-button:hover {
  background-color: #019aad;
}

@media (max-width: 480px) {
  .signup-form-container.step-7 {
    padding: 20px;
  }

  .confirmation-title {
    font-size: 20px;
  }

  .confirmation-message {
    font-size: 14px;
    white-space: normal;
  }
}

.step7_button-container{
  width: 75%;
}


.step7-container {
  justify-content: space-between;
  max-width: 100%;
  min-height: 420px;
  top: -7vh;
  width: 100%;
  display: flow;
  align-content: center;
  text-align: -webkit-center;
}

.logo2 {
  width: 180px;
  max-width: 100%;
  height: auto;
  width: 242px;
}

.confirmation-title {
  font-family: 'Figtree', sans-serif;
  font-size: 48px;
  color: #333;
  white-space: nowrap;
}

.confirmation-message {
  font-family: 'Figtree', sans-serif;
  font-size: 16px;
  color: #666;
  line-height: 1.5;
  white-space: nowrap;
}

.next-button {
  background-color: #01B6CD;
  color: white;
  font-family: 'Figtree', sans-serif;
  font-size: 16px;
  padding: 10px 0;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  border: none;
  transition: background-color 0.3s ease;
}

.next-button:hover {
  background-color: #019aad;
}

@media (max-width: 480px) {
  .signup-form-container.step-7 {
    padding: 20px;
  }

  .confirmation-title {
    font-size: 20px;
  }

  .confirmation-message {
    font-size: 14px;
    white-space: normal;
  }
}

