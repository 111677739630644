.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 900px; /* 모달의 최대 너비를 적절히 조정 */
  width: 100%;
  max-height: 90vh; /* 모달의 최대 높이 설정 */
  overflow-y: auto; /* 수직 스크롤바 추가 */
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 35px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
