.dpp-doctor-profile {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f5f5f5;
}

.dpp-content {
  display: flex;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.dpp-sidebar {
  width: 250px;
  background-color: #f5f5f5;
  border-radius: 10px 0 0 10px;
  padding: 20px 0;
}

.dp_uploadBtn{
  width: 127px;
  height: 37px;
  border: solid 2px #01B6CD;
  font-size: 16px;
  font-weight: bold;
  color: #01B6CD;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.dp_gender{
  background-color: initial;
  font-size: 20px;
  padding: 10px 0;
  transition: border-color .3s;
}

.dpp-sidebar nav {
  display: flex;
  flex-direction: column;
}

.dpp-sub-containerA{
  height: 0px;
}

.dpp-sidebar button.active {
  background-color: #fff;
  color: #2F3637;
  font-weight: 700;
}

.dpp-main-contentA {
  flex-grow: 1;
  overflow-y: scroll;
  padding: 30px;
  max-height: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.dpp-main-contentA::-webkit-scrollbar{
  display:none;
}

.dpp-main-content h1 {
  font-size: 1.8rem;
  margin-bottom: 30px;
  color: #333;
}

.dp_section1 {
  display: flex;
  gap: 30px;
  max-height: 1090px;
}

.dp_drPicContainer {
  width: 150px;
  display: flow;
  text-align: center;
}

.dp_drPicContainer img {
  border-radius: 50%;
  height: 150px;
  object-fit: cover;
  width: 150px;
}

.dp_drPicContainer button {
  background-color: #fff;
  border: 2px solid #01b6cd;
  border-radius: 10px;
  color: #01b6cd;
  cursor: pointer;
  margin-top: 10px;
  padding: 8px;
  width: 127px;
  font-size: 16px;
  font-weight: bold;
  height: 37px;
}

.dp_mainContents {
  width: 600px;
}

.dp_Account, .dp_Medical, .dp_Experience, .dp_introduction, .dp_CloseRemarks {
  margin-bottom: 30px;
}

.dp_label {
  font-weight: bold;
  margin-bottom: 20px;
  color: #666;
  left: 0;
  font-size: 20px;
  transition: all 0.3s;
}

.dp_container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 10px;
}

.dp_textarea_A, .dp_textarea_B, .dp_textarea_C {
  padding: 10px;
  border: none;
}


.dp_textarea_A {
  flex: 1;
}

.dp_textarea_B {
  width: 100px;
}

.dp_textarea_C {
  flex: 1;
}

.dpp-radio-group {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.dpp-radio-label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.dp-search-container {
  margin-bottom: 20px;
}

.dp-address-search-input {
  width: 580px;
  padding: 10px 10px 10px 10px;
  border: none;
  border-radius: 10px;
  background-color: #EFF2F3;
  background-image: url('./icons/search-icon.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
}

.dp-address-search-input:focus{
  color: #9CABAD;
}

.dp-address-details {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.dp-input-wrapper {
  flex: 1;
}

.dp-input, .dp-custom-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.dp-input-group {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.dp-input-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.dp-address-input {
  border: none;
  flex-grow: 1;
  padding: 5px;
}

.dp_medicalLisenceContainer {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.dp_MedicalContainer, .dp_LisenceContainer {
  flex: 1;
}

.dp_image{
  width: 24px;
  height: 24px;
}

.dp_MedicalBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  width: 288px;
  height: 44px;
}

.dp_LisenceContainer img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
}

.dp_label2 {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 10px;
}

.dp_LisenceContainer button {
  padding: 5px 10px;
  background-color: white;
  border: 1px solid #01B6CD;
  color: #01B6CD;
  border-radius: 5px;
  cursor: pointer;
}

.dp_textarea_D, .dp_textarea_E {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  height: 56px;
  resize: none;
  -ms-overflow-style: none;
}

.dp_textarea_D, .dp_textarea_E::-webkit-scrollbar{
  display:none;
}

.dp_saveBtn {
  background-color: #01b6cd;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  float: right;
  font-size: 20px;
  width: 127px;
  height: 37px;
  position: relative;
  transition: opacity 0.3s;
}

/* Additional styles for better matching */
.wcc-header {
  background-color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.wcc-logo-image {
  height: 40px;
}

.wcc-header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.wcc-notification-button,
.wcc-drprofile-button {
  background: none;
  border: none;
  cursor: pointer;
}

.wcc-rate-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.wcc-rate-img {
  width: 20px;
  height: 20px;
}

.wcc-rate-text {
  font-size: 0.9rem;
}

.wcc-drprofile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.dp_textarea_A, .dp_textarea_B, .dp_textarea_C, 
.dp_textarea_D, .dp_textarea_E,
.dp-input, .dp-custom-select{
  padding: 5px 0;
  font-size: 20px;
  background-color: transparent;
  transition: border-color 0.3s;
  width: 100%;
}

.dp-input-wrapper3 {
  border: 1px solid #b9c3c4;
  border-radius: 5px;
  height: 141px;
  margin-bottom: 20px;
  padding: 0px 10px 0;
  position: relative;
  width: 100%;
}

.dp-input-wrapper {
  border: 1px solid #b9c3c4;
  border-radius: 5px;
  height: 46px;
  margin-bottom: 20px;
  padding: 0px 10px 0;
  position: relative;
  width: 178px;
}

.dp-input-wrapper3 label,
.dp-input-wrapper label{
  left: 0;
  font-size: 14px;
  color: #666;
  transition: all 0.3s;
}

.dp_ExperienceTextBox{
  resize: none;
  min-height: 40px;
  height: auto;
  overflow-y: auto;
  -ms-overflow-style: none;
}

.dp_ExperienceTextBox::-webkit-scrollbar{
  display:none;
}

.dp_MainTitle{
  font-size: 28px;
  color: #2E7C9A;
  font-weight: bold;
  margin-top: 18px;
  margin-bottom: 18px;
}

.dp-input-wrapper input:focus + label,
.dp-input-wrapper input:not(:placeholder-shown) + label,
.dp_textarea_A:focus + .dp_label,
.dp_textarea_A:not(:placeholder-shown) + .dp_label {
  top: -20px;
  font-size: 10px;
  color: #01B6CD;
}

.dp-address-search-input {
  background-image: url('./icons/search-icon.png');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 15px;
}

/* 추가적인 스타일 수정 */
.dp-input-group {
  border: none;
  padding: 0;
}

.dp-address-input {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.dp_textarea_D, .dp_textarea_E {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 578px;
  height: 31px;
  font-size: 16px;
}

.dp-custom-select {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.gender-select-wrapper {
  position: relative;
}

.custom-select {
  position: relative;
}

.dp_gender {
  background-color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-size: 20px;
  margin-top: 17px;
  padding: 5px 30px 0 0;
  width: 100%;
}

.dp_gender:focus {
  outline: none;
  border-color: #FFF; /* 또는 원하는 색상 */
}

.dp_gender::-ms-expand {
  display: none;
}

.dp-input-wrapper.gender-select-wrapper label {
  background-color: #fff;
  color: #666;
  font-size: 14px;
  left: 9px;
  padding: 0 5px;
  position: absolute;
}

.dp_container {
  display: flex;
  gap: 20px;
}

.dp-input-wrapper {
  flex: 1;
  border: 1px solid #b9c3c4;
  border-radius: 5px;
  padding: 5px 10px;
  position: relative;
}

.dp_textarea_B, .dp_textarea_C {
  width: 100%;
  border: none;
  font-size: 20px;
  padding: 5px 0;
  background-color: transparent;
}

.dp_textarea_C2{
  width: 100%;
  border: none;
  font-size: 16px;
  padding: 5px 0;
  background-color: transparent;
  height: 75%;
}

.dp_textarea_B:focus, .dp_textarea_C:focus {
  outline: none;
}

.date-of-birth-wrapper, .phone-number-wrapper {
  height: 46px;
}

.state-select-wrapper {
  position: relative;
}

.dp_state {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
  margin-top: 17px;
  padding: 5px 30px 0 0;
  width: 100%;
}

.dp_state:focus {
  outline: none;
}

.dp_state::-ms-expand {
  display: none;
}

.state-select-wrapper .custom-select::after {
  content: "\25BC";
  font-size: 14px;
  top: 50%;
  right: 10px;
  position: absolute;
  transform: translateY(-50%);
  pointer-events: none;
}

.dp-input-wrapper.state-select-wrapper label {
  background-color: white;
  color: #666;
  font-size: 14px;
  left: 5px;
  padding: 0 5px;
  position: absolute;
}

.dp_LicenseContainer {
  flex: 1;
  position: relative;
  text-align: -webkit-center;
}

.dp_license-preview-container {
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 150px;
  overflow: hidden;
  position: relative;
  width: 168px;
  height: 83px;
  text-align: center;
  background-color: #EFF2F3;
}

.dp_license-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dp_license-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #666;
  font-size: 14px;
}

.dp_remove-license {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0,0,0,0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 16px;
  cursor: pointer;
}

.dp_license-filename {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.dp_update-license {
  border: none;
  background-color: #01B6CD;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
  padding: 5px 10px;
  width: 127px;
  height: 37px;
  font-size: 14px;
  font-weight: bold;
}

.dp_verified {
  color: #4CAF50;
  margin-left: 10px;
}

.dp-input-wrapper .custom-date-picker {
  width: 100%;
}

.dp-input-wrapper .custom-date-input {
  width: 100%;
  border-radius: 8px;
  background-color: white;
}

.dp-input-wrapper .custom-date-input input {
  width: 100%;
  padding: 5px 0px 0px 0px;
  border: none;
  background: transparent;
  font-size: 20px;
  color: #333;
}

.dp-input-wrapper .custom-date-input .calendar-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

.dp-input-wrapper .custom-date-input.has-error {
  border-color: #ff4d4f;
}

/* DatePicker 드롭다운 스타일링 */
.custom-header {
  padding: 8px;
  background-color: #f8f9fa;
}

.custom-header select {
  margin: 0 4px;
  padding: 4px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
}

.hereisChange{}

.dp_drPicContainer {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
  background-color: #f5f5f5;
}

.dp_profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dp_profile-picture-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.dp_user-icon {
  width: 60%;
  height: 60%;
  opacity: 0.5;
}

.dp_camera-icon-overlay {
  align-items: center;
  background-color: #ff000000;
  bottom: 0;
  cursor: pointer;
  display: flex;
  height: 150px;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  transition: background-color .3s ease;
}

.dp_camera-icon {
  width: 24px;
  height: 24px;
  filter: invert(1);
}

/* 기존 버튼 제거를 위한 스타일 */
.dp_drPicContainer > button {
  display: none;
}

.dp-predictions-list{
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  margin-top: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.dp-prediction-item{
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.dp-prediction-item:last-child {
  border-bottom: none;
}

.dp-prediction-item:hover {
  background-color: #f5f5f5;
}

.dp-search-container {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.dp-search-input-wrapper {
  position: relative;
}

.dp-address-search-input {
  background-repeat: no-repeat;
}

.dp-search-loading {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  font-size: 12px;
}

.dp-predictions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
}

.dp-prediction-item {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dp-prediction-item:hover {
  background-color: #f5f5f5;
}

.dp-location-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.dp_saveBtn.dp_loading-button {
  opacity: 0.7;
  cursor: not-allowed;
}

.dp_saveBtn .dp_button-text {
  display: inline-block;
  transition: opacity 0.2s;
}

.dp_saveBtn .dp_button-text.dp_loading {
  opacity: 0;
}

.dp_saveBtn .dp_loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: dp_spin 1s linear infinite;
}

@keyframes dp_spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}