.chat-container {
  display: flex;
  flex-direction: column;
  height: 92vh;
  background-color: #F1F9FC;
}

.typing-indicator {
  align-items: center;
  bottom: 110px;
  display: flex;
  left: 20px;
  position: absolute;
}

.message-text-time-container {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  flex-direction: row-reverse;
  justify-content: left;
}

.message-text-time-container.mine {
  flex-direction: row-reverse;
}

.message-text {
  flex: 1;
  word-break: break-word;
}

.message-time {
  white-space: nowrap;
  font-size: 0.8em;
  color: #888;
  align-self: flex-end;
}

.message-time.mine {
  text-align: right;
}

.message-content {
  display: flex;
  flex-direction: column;
}

.message-image,
.message-video,
.message-pdf {
  max-width: 100%;
}

.typing-gif {
  width: 50px;
  height: auto;
}

.read-check-icon {
  width: 16px;
  height: 16px;
}

.message-list {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.message-list::-webkit-scrollbar {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #D3D8D9;
}

.message-list::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #2E7C9A;
}

.message-time {
  display: flex;
  align-items: center;
  font-size: 0.8em;
  color: #888;
}

.read-receipt {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.date-separator {
  background-color: #d9d9d9;
  border-radius: 20px;
  color: #5e5e5e;
  font-size: .9em;
  font-weight: 700;
  padding: 10px;
  text-align: center;
  width: 100%;
  align-self: center;
  max-width: fit-content;
}

.message-group {
  display: flex;
  align-items: flex-start;
}

.message-group.doctor {
  flex-direction: row;
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  max-width: 50%;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #FFF;
  background-size: cover;
  flex-shrink: 0;
}

.message-group.patient .avatar {
  margin-right: 8px;
}

.message-group.doctor .avatar {
  margin-left: 8px;
}

.message-content-wrapper {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 48px);
  margin-bottom: 10px;
}

.message-group.doctor .message-content-wrapper {
  align-items: flex-end;
}

.message-item {
  display: flex;
  margin-bottom: 2px;
  align-items: flex-end;
}

.message-item.doctor {
  flex-direction: row;
  justify-content: flex-end;
  min-width: fit-content;
}

.message-content {
  display: inline-block;
  border-radius: 18px;
  padding: 8px 12px;
  max-width: calc(100% - 100px);
  min-width: inherit;
}
.message-content-video video,
.message-content-image img {
  border-radius: 5%;
  border-radius: 5%;
  max-width: 95%;
  float: right;
}
.message-content-video,
.message-content-image {
  border-radius: 18px;
  display: inline-block;
  max-width: calc(50% - 100px);
  min-width: inherit;
}


.message-item.doctor .message-content {
  background-color: #01B6CD;
  color: white;
}

.message-item.patient .message-content {
  background-color: #E5E5EA;
  color: black;
  min-inline-size: fit-content;
}

.message-status {
  align-items: flex-end;
  display: flex;
  flex-direction: row-reverse;
  margin-right: 4px;
}

.message-time {
  font-size: 0.7em;
  color: #999;
  white-space: nowrap;
  margin: 0 4px;
}

.read-check-icon {
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
}

.avatar-placeholder {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.sender {
  font-weight: bold;
  font-size: 0.9em;
  margin-bottom: 4px;
}

.admin-message-content {
  align-self: center;
  background-color: #F1F9FC;
  border-radius: 8px;
  padding: 8px 12px;
  margin: 4px 0;
}

.admin-message-container {
  text-align: center;
  color: #666;
  font-style: normal;
}

.message-group.patient-admin {
  /* admin 메시지를 포함하는 환자 메시지 그룹에 대한 스타일 */
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.message-input.frozen {
  background-color: #f0f0f0;
  opacity: 0.7;
}

.message-input.frozen .chat-input {
  color: #888;
}

.message-input.frozen .file-upload-label {
  opacity: 0.5;
  cursor: not-allowed;
}

.message-input.frozen .file-upload-label img {
  filter: grayscale(100%);
}

.admin-message-container::before,
.admin-message-container::after {
  content: "";
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 16px;
  width: 70%;
}

.message-input {
  background-color: #fff;
  display: flex;
  border: 2px solid #C9C9C9;
  border-radius: 15px;
  margin-top: 10px;
  align-self: center;
  width: 96%;
}

.chat-input {
  flex-grow: 1;
  border: none;
  background-color: transparent;
  font-size: 14px;
  padding: 15px;
  outline: none;
  border-radius: 20px;
}

.message-input-buttons {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.file-upload-label {
  cursor: pointer;
  margin-right: 10px;
}

.file-upload-label img {
  width: 20px;
  height: 20px;
}

.file-upload-button {
  display: none;
}

.pdf-link {
  color: #FFFFFF;
}

.message-pdf {
  word-wrap: break-word;
  padding: 8px 12px;
  background-color: #01B6CD;
  border-radius: 15px;
  display: block;
}

.message-image {
  border-radius: 8px;
  max-width: 100%;
}

.message-video {
  max-width: 100%;
  border-radius: 8px;
}