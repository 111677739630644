.sdp-div {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 464px;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
}

.sdp-div::-webkit-scrollbar {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #D3D8D9;
}

.sdp-div::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 50px;
  border: none;
  background-color: #2E7C9A;
}

.sdp-div.open {
  transform: translateX(0);
}

.sdp-div-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sdp-img, .sdp-img-2 {
  width: 37px;
  height: 37px;
  object-fit: contain;
  cursor: pointer;
}

.sdp-div-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.sdp-img-3 {
  width: 138px;
  height: 138px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.sdp-james-adams {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 15px;
}

.sdp-info-row {
  display: flex;
  margin-bottom: 15px;
}

.sdp-info-label {
  color: #2E7C9A;
  font-size: 16px;
  font-weight: 600;
  width: 130px;
}

.sdp-info-value {
  color: #000;
  font-size: 16px;
  font-weight: 300;
}

.sdp-div-7, .sdp-div-9 {
  margin: 20px 0;
  border: solid 2px #9CABAD;
}

.sdp-medical-department {
  color: #2E7C9A;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 13px;
}

.sdp-div-8 {
  align-items: center;
  background-color: #fff;
  border: 1px solid #2E7C9A;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  padding: 10px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #2F3637;
  cursor: pointer;
}

.sdp-img-4 {
  height: 24px;
  margin-right: 5px;
  width: 24px;
}

.sdp-info-section {
  margin-bottom: 15px;
}

.sdp-info-title {
  color: #2E7C9A;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 13px;
}

.sdp-info-content {
  color: #2F3637;
  font-size: 16px;
}

.sdp-InfoContainer{
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}