.help-section {
  padding: 20px;
  width: 800px;
  font-family: 'Anek Devanagari', sans-serif;
  position: relative;
  min-height: 100vh;
  place-self: center;
}

.help-section h1 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #2E7C9A;
}

.search-instruction {
  margin-bottom: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}

.search-bar {
  display: flex;
  margin-bottom: 30px;
  position: relative;
  justify-content: center;
}

.search-bar input {
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  font-size: 16px;
  padding: 20px;
  color: #839699;
  width: 373px;
  height: 9px;
  background-color: rgba(46, 124, 154, 0.2);
}

.search-bar input::placeholder{
  color: #839699;
}


button.dpp-menubutton.ap {
  background: gray;
}

.search-bar button {
  position: absolute;
  right: 210px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.search-bar button img {
  width: 20px;
  height: 20px;
}

.popular-topics {
  margin-bottom: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #9cabad;
}

.popular-topics_divh2 {
  align-content: center;
  color: #2e7c9a;
  font-size: 20px;
  font-weight: 500;
  font-weight: 700;
  height: 59px;
  padding-left: 20px;
}

.faq-list {
  border: 1px solid #e0e0e0;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.faq-item {
}

.faq-item:last-child {
  border-bottom: none;
}

.faq-question {
  align-items: center;
  background-color: #F1F9FC;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  height: 20px;
  font-weight: bold;
}

.Hs_customerService{
  background-color: #fff;
  bottom: 40px;
    display: block;
    height: 37px;
    padding: 10px;
    position: fixed;
    display: grid;
    gap: 10px;
    left: 315px;
}

.Hs_OtherIssues{
  font-size: 16px;
  color: #9CABAD;
}

.Hs_CustomerServiceBtn{
  color: #01B6CD;
  cursor: pointer;
  font-size: 20px;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease;
  background-color: #f1f9fc;
  padding: 0 15px;
}

.faq-answer.active {
  max-height: 1000px;
  padding: 15px;
  transition: max-height 0.5s ease-in, padding 0.3s ease;
}

.arrow {
  border: solid #333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transition: transform 0.3s ease;
}

.arrow.down {
  transform: rotate(-45deg);
}

.arrow.up {
  transform: rotate(45deg);
}