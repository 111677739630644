@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@400;700&family=Actor&family=Baloo+Bhaina+2:wght@400;700&display=swap');

.expired-call-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

 
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 80%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 510px;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-content h2 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
  font-size: 20px;
  font-family: 'Baloo Bhaijaan 2', cursive;
  font-weight: 700;
}

.popup-content p {
  margin-bottom: 20px;
  color: #5E5E5E;
  font-size: 14px;
  font-family: 'Actor', sans-serif;
}

.popup-content button {
  background-color: #FEDCDC;
  color: #FA5252;
  border: none;
  padding: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Baloo Bhaina 2', cursive;
}