.channel-list-item {
  height: 80px;
  background-color: #fff;
  max-width: 392px;
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  align-content: center;
  position: relative;
}

.channel-list-item:not(.selected):hover {
  background-color: #E3F2FD;
}

/* 선택된 아이템의 스타일 */
.channel-list-item.selected {
  background-color: #b2ebf2;
}

.channel-list-content {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  background-color: transparent;
}

.channel-list-icon {
  align-items: center;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin-left: 10px;
  position: relative;
  width: 46px;
  border-radius: 50%;
}

.channel-list-icon-image {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.channel-list-details {
  display: flex;
  gap: 7px;
  color: #000;
  align-self: center;
}

.channel-list-info {
  display: flex;
  flex-direction: column;
}

.channel-list-name-box {
  display: flex;
  flex-direction: row;
  width: 201px;
}

.channel-list-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.channel-list-name {
  font: 600 20px Baloo Paaji 2, -apple-system, Roboto, Helvetica, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  font-size: 18px;
}

.channel-list-name.min-content {
  width: min-content;
}

.channel-list-name.fixed-width {
  width: 117px;
}

.channel-list-user-info {
  font-size: 16px;
  white-space: nowrap;
  margin-left: 7px;
}

.channel-list-status {
  border-radius: 50%;
  width: 11px;
  height: 11px;
  margin: auto 0 auto 7px;
}

.channel-list-status-active {
  background-color: #52fa63;
}

.channel-list-status-inactive {
  background-color: red;
}

.channel-list-department {
  margin-top: 5px;
  font-weight: bold;
  font: 400 14px Actor, sans-serif;
}

.channel-list-unread-badge {
  align-items: center;
  background-color: #ff6c6c;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  height: 19px;
  justify-content: center;
  position: absolute;
  right: 30px;
  width: 19px;
  top: 50%;
  transform: translateY(-50%);
}