.sdmf_modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: #00000080;
}

.sdmf_department-container {
  width: 692px;
  max-height: 240px;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.sdmf_department-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.sdmf_modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 763px;
  height: 450px;
  text-align: -webkit-center;
}

.sdmf_title {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.sdmf_subtitle {
  font-size: 16px;
  color: #666;
  text-align: center;
  margin-bottom: 40px;
}

.sdmf_department-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 17px;
  margin-bottom: 30px;
}

.sdmf_department-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 4px solid #01B6CD;
  border-radius: 15px;
  width: 98px;
  height: 98px;
  margin-top: 20px;
  flex: 0 0 calc(17% - 21px);
}

.sdmf_department-icon {
  height: 66px;
  margin-bottom: 7px;
  width: 66px;
}

.sdmf_department-name {
  font-size: 12px;
  text-align: center;
  color: #01B6CD;
}

.sdmf_cancel-button {
  background-color: initial;
  border: 3px solid #9CABAD;
  border-radius: 15px;
  color: #9CABAD;
  cursor: pointer;
  font-size: 20px;
  padding: 10px 20px;
  width: 195px;
  height: 48px;
}

.sdmf_button-center {
  text-align: center;
  margin-top: 20px;
}