/* 웹 폰트 import (HTML <head> 섹션에 추가해야 함)
<link href="https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@700&family=Inter:wght@400;700&display=swap" rel="stylesheet">
*/

.AccountSection .account-section {
  padding: 40px;
  background-color: #ffffff;
  max-width: 600px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
}

.account-section h1 {
  font-family: 'Baloo Bhaijaan 2', cursive;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 40px;
  color: #333;
}

.as-input-wrapper {
  border: 1px solid #b9c3c4;
  border-radius: 5px;
  height: 52px;
  margin-bottom: 20px;
  padding: 0px 10px 0;
  position: relative;
  width: 325px;
}

.as-input-wrapper {
  flex: 1;
  border: 1px solid #b9c3c4;
  border-radius: 5px;
  padding: 5px 10px;
  position: relative;
}

.as-input-wrapper label{
  left: 0;
  font-size: 14px;
  color: #666;
  transition: all 0.3s;
}

.as_label {
  font-weight: bold;
  margin-bottom: 20px;
  color: #666;
  left: 0;
  font-size: 20px;
  transition: all 0.3s;
}

.AccountSection button.dpp-menubutton.ap {
  background: gray;
}

.AccountSection .input-group {
  margin-bottom: 40px;
}

.AccountSection .input-group h2 {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #333;
}

.AccountSection .input-group input {
  width: 100%;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  margin-bottom: 20px;
}

.AccountSection .input-group input:disabled {
  background-color: #f5f5f5;
  color: #888;
}

.AccountSection .input-group input::placeholder {
  color: #aaa;
}

.AccountSection .button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  gap: 20px; /* 버튼 사이의 간격 */
}

.AccountSection .button-group button {
  padding: 15px 30px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  cursor: pointer;
}

.AccountSection .leave-account {
  background-color: #ff6b6b;
  color: white;
}

.AccountSection .save {
  background-color: #00bcd4;
  color: white;
  margin-left: auto; /* Save 버튼을 오른쪽으로 밀어냄 */
}

.AccountSection .error-message {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 30px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
}

.as_changePwBtn{
  border: solid 2px #B9C3C4;
  color: #01B6CD;
  background-color: #fff;
  width: 347px;
  height: 53px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
}

.As_delete-account {
  border: 2px solid #ff1e00;
  border-radius: 10px;
  background-color: #fff;
  color: #ff1e00;
  cursor: pointer;
  display: block;
  font-size: 16px;
  margin-top: 30px;
  padding: 10px;
  transition: background-color .3s, color .3s;
  width: 158px;
  height: 37px;
  font-weight: 300;
  position: fixed;
  bottom: 10px;
}

.As_delete-account:hover {
  background-color: #ff6b6b;
  color: white;
}