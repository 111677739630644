.step4-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

.st4_su_h2 {
  font-family: 'Figtree';
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 5px;
  padding-top: 10px;
  text-align: center;
  color: black;
}

.st4_subtitle {
  text-align: center;
  color: #666;
  margin-bottom: 35px;
  font-size: 20px;
}

.st4_department-container {
  height: 250px;
  overflow-y: auto;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 32px;
}

.st4_department-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  margin-left: 3px;
  margin-top: 3px;
}

.st4_department-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 5px solid #fff;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 5px #c8c8c8;
  width: 100px;
  height: 90px;
}

.st4_department-button.active {
  border: solid 5px #01b6cd;
  color: #01b6cd;
}

.st4_department-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

.st4_department-name {
  font-size: 12px;
  text-align: center;
}

.st4_button_center {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  text-align-last: center;
}

.next-button {
  background-color: #01b6cd;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Scrollbar styles */
.st4_department-container::-webkit-scrollbar {
  width: 6px;
}

.st4_department-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.st4_department-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.st4_department-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Responsive styles */
@media (max-width: 768px) {
  .st4_department-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 480px) {
  .st4_department-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .st4_su_h2 {
    font-size: 20px;
  }
  
  .st4_subtitle {
    font-size: 14px;
  }
}